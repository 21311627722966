.c-letter-slide-list__checkbox-item {
  position: relative;
  max-width: rem-calc(295);
  width: 100%;

  input {
    @include visually-hidden();
  }

  input:checked + &__button {
    outline: none;
    @include focus-handler();
    &::before {
      background: $miami;
      transform: rotate(180deg);
      opacity: 0.5;
      border-radius: rem-calc(14);
    }

    &::after {
      background: c('umo-purple');
    }

    .c-letter-slide-list__checkbox-item__icon {
      background: url('../../../framework/images/umo/checkbox-checked.svg')
        no-repeat center/contain;
    }
  }

  &__icon {
    display: flex;
    width: rem-calc(25);
    height: rem-calc(25);
    flex-shrink: 0;
    background-color: #fff;
    border-radius: rem-calc(5);
    background: url('../../../framework/images/umo/checkbox-unchecked.svg')
      no-repeat center/contain;
  }

  &__button {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: rem-calc(10);
    position: relative;
    max-width: rem-calc(295);
    padding: rem-calc(10);
    font-size: rem-calc(16);
    font-family: $secondary-font;
    word-break: break-word;
    outline: 1px solid black;
    border-radius: rem-calc(10);
    box-shadow: $umo-drop-shadow;

    &--full-width {
      width: 100%;
    }

    &--shortened {
      width: 85%;
    }

    & > * {
      z-index: 1;
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      border-radius: inherit;
      background: transparent;
      transition: background 0.3s ease;
    }

    &::before {
      top: -4px;
      left: -4px;
      right: -4px;
      bottom: -4px;
    }

    &::after {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $black-05;
    }

    @include focus-handler();
  }

  button {
    position: absolute;
    right: -3px;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-inline: rem-calc(20);
    width: rem-calc(40);
    height: 100%;
    border-radius: rem-calc(5);
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      width: rem-calc(12.8);
      height: rem-calc(16);
      background: url('../../../framework/images/trash-can.svg') center/contain
        no-repeat;
    }
  }
}
