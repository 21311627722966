@if $editor=='true' {
  body {
    @include default();
    @include base-font-family();
  }
}

p {
  @include secondary-font-family();
  display: block;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: normal;
  margin-top: 0;

  @if $editor=='true' {
    @include base-font-family();
    margin-bottom: rem-calc(25);
    editmenuname: Paragraf;
  }

  @else {
    margin-bottom: rem-calc(20);

    @include breakpoint(medium) {
      margin-bottom: rem-calc(25);
    }
  }
}

h1 {
  @include h1(true);
  @include base-font-family();
  letter-spacing: 0.5px;
  font-weight: 400;

  @include site-theme(umo) {
    color: c('red-extra');
  }

  @include site-theme(youmo) {
    color: $green-header;
  }
}

h2 {
  @include h2(true);
  @include base-font-family();
  letter-spacing: 0.25px;
  font-weight: 400;
  color: c('red-extra');

  @if $editor=='true' {
    editmenutitle: Rubriker;
    editmenuname: H2 Rubrik;

    @include site-theme(umo) {
      color: c('red-extra');
    }

    @include site-theme(youmo) {
      color: $green-header;
    }
  }
}

h3 {
  @include secondary-font-family();
  @include heading();
  @include h3(true);

  @if $editor=='true' {
    editmenuname: H3 Rubrik;
    color: c('grey-600');
  }
}

h4 {
  @include secondary-font-family();
  @include heading();

  @if $editor=='true' {
    editmenuname: H4 Rubrik;
    color: c('grey-600');
  }
}

ul,
ol {
  @include content-lists-common();
  @include secondary-font-family();

  @media print {
    padding-left: 12px !important;
  }
}

ol {

  .c-chapter &,
  .mceContentBody &,
  .mce-content-body &,
  .u-content-editable &,
  .c-highlight &,
  .c-image-gallery__item__text &,
  .c-media-gallery__item__text & {
    @include ol-list();
  }
}

ul {

  .c-chapter &,
  .mceContentBody &,
  .mce-content-body &,
  .u-content-editable &,
  .c-highlight &,
  .c-image-gallery__item__text &,
  .c-media-gallery__item__text & {
    @include ul-list($secondary);
  }
}

.c-chapter,
.mceContentBody,
.mce-content-body,
.u-content-editable,
.c-highlight {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @if $editor=='true' {
      margin-top: rem-calc(35);
    }

    @else {
      margin-top: rem-calc(30);

      @include breakpoint(large) {
        margin-top: rem-calc(35);
      }
    }
  }
}

form {

  label,
  input {
    @include secondary-font-family();
  }
}

@if $editor=='true' {

  .mceContentBody,
  .mce-content-body {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: rem-calc(35);
    }
  }
}

a {
  color: c('text-link');
  text-decoration: none;

  &:hover {
    color: c('link-hover');
    text-decoration: underline;
  }

  &:active {
    color: c('link-hover');
  }

  text-decoration: none;

  @include focus-handler();

  @media print {
    text-decoration: underline;
  }
}

mark {
  @media print {
    print-color-adjust: exact; //Firefox
    -webkit-print-color-adjust: exact; //Chrome, Safari, Edge
  }

  &.c-highlight-text {
    &--pink {
      background-color: #fcd2cc;
    }

    &--green {
      background-color: #d5e3ab;
    }

    &--blue {
      background-color: #bce0f0;
    }

    &--purple {
      background-color: #eaccf5;
    }
  }
}

blockquote {
  color: c('grey-600');

  margin: 0 0 rem-calc(30);
  padding: 0 rem-calc(10) rem-calc(20);
  position: relative;
  font-family: 'Aleo';
  font-style: italic;
  font-weight: 400;
  line-height: 30px;
  font-size: 20px;

  p {
    color: c('grey-800');
    background: none;
    font-size: 20px;
    margin: 0;
    padding: 4px 10px;

    @include breakpoint(large) {
      font-size: 24px;
      line-height: 36px;
    }

    @media print {
      background-color: transparent !important;
      background-image: none !important;
    }
  }

  &:before {
    content: url(../../images/quote-fade-red.svg);
    display: block;
  }

  &.u-quote {
    &--dark {
      p {
        background: #4d4d4d;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        color: c('white');
      }

      cite {
        margin-left: 10px;
      }
    }

    &--purple {
      p {
        background-image: radial-gradient(104.88% 171.71% at 103.05% 51.64%,
            rgba(201, 175, 255, 0.5) 0%,
            rgba(221, 240, 247, 0.5) 100%);
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;
        color: c('grey-800');
      }

      cite {
        margin-left: 10px;
      }

      &:before {
        content: url(../../images/quote.svg);
      }
    }
  }

  @include breakpoint(large) {
    @if $editor=='true' {
      margin: rem-calc(10) rem-calc(30) 0 0;
    }

    @else {
      margin: rem-calc(10) rem-calc(30) 0 -20%;
    }

    width: 65%;
    text-align: left;

    [dir='rtl'] & {
      text-align: right;
    }

    @media print {
      text-align: left !important;
      width: 65% !important;
      float: left !important;
      margin: 0 0 30px !important;
    }
  }

  @include breakpoint(medium) {
    width: 65%;
  }

  @if $editor=='true' {
    editmenuname: Citat;
  }
}

cite {
  color: c('grey-800');
  display: block;
  @include secondary-font-family();
  font-style: italic;
  line-height: 30px;

  @if $editor=='true' {
    editmenuname: Citatkalla;
  }

  @include breakpoint(medium) {
    text-align: left;

    [dir='rtl'] & {
      text-align: right;
    }
  }
}

.preamble {
  @include lead();
  @include base-font-family();

  color: c('grey-700');

  font-size: 1.25rem;
  letter-spacing: 0px;

  @include breakpoint(large) {
    font-size: 1.375rem;
    letter-spacing: 0.25px;
  }

  @media print {
    font-size: 14px !important;
  }

  &--narrow {
    margin-left: auto;
    margin-right: auto;
    max-width: 620px;
  }
}

i,
em {
  font-style: italic;
}
