@import './core-functions.scss';

$theme-color-interval: 0.1;

//Base colors

$theme-article-gradient: radial-gradient(101.94% 101.94% at 100% 100%,
  #e5e5e5 0.52%,
  rgba(242, 242, 242, 0.2) 100%);

$theme-faq-gradient: radial-gradient(101.02% 104.36% at 100% 100%,
  rgba(201, 175, 255, 0.5) 0%,
  rgba(221, 240, 247, 0.5) 42.92%);

$theme-forum-gradient: radial-gradient(100% 100% at 100% 100%,
  rgba(234, 241, 213, 0.25) 0%,
  rgba(240, 30, 0, 0.075) 100%);

$theme-office-gradient: radial-gradient(101.94% 101.94% at 100% 100%,
  #c3dce6 0.52%,
  rgba(235, 235, 235, 0.09) 100%);
$theme-story-gradient: radial-gradient(101.94% 101.94% at 100% 100%,
  rgba(221, 240, 247, 0.5) 0%,
  rgba(234, 241, 213, 0.5) 76.04%);
$theme-podcast-gradient: radial-gradient(101.94% 101.94% at 100% 100%,
  #e5e5e5 0.52%,
  #f5e5fa 100%);
$theme-shortstory-gradient: radial-gradient(101.94% 101.94% at 100% 100%,
  #f3e1df 0.52%,
  rgba(235, 235, 235, 0.09) 100%);

$theme-tip-gradient: radial-gradient(100% 100% at 100% 100%,
  rgba(240, 30, 0, 0.075) 0%,
  rgba(234, 241, 213, 0.25) 68.23%);

$theme-support-gradient: radial-gradient(101.94% 101.94% at 100% 100%,
  #c3dce6 0.52%,
  rgba(235, 235, 235, 0.09) 100%);

$rainbow: linear-gradient(138.17deg,
  rgba(188, 224, 240, 0.2) 24.01%,
  rgba(213, 153, 235, 0.2) 76.79%,
  rgba(240, 30, 0, 0.2) 114.05%);

$rainbow-v2: linear-gradient(160deg,
  #eef7fb 16.58%,
  #f5e5fa 64.47%,
  #fcd2cc 98.26%);

  $peachy-purple: linear-gradient(
    93deg,
    rgba(230, 31, 0, 0.5) 0%,
    rgba(151, 0, 204, 0.5) 103.2%
  ),
  #fff;

$bluesky: radial-gradient(142.36% 141.42% at 100% 100%,
  #bce0f0 0.52%,
  #fafafa 100%);

$shimmer: linear-gradient(119deg,
  rgba(188, 224, 240, 0.2) 63.17%,
  rgba(213, 153, 235, 0.2) 97.59%,
  rgba(240, 30, 0, 0.2) 113.68%),
#fff;

$miami: linear-gradient(270deg,
  rgba(230, 31, 0, 1) 0%,
  rgba(151, 0, 204, 1) 100%);

$miami-rev-50: linear-gradient(
  270deg,
  rgba(203, 128, 230, 1) 0%,
  rgba(243, 143, 128, 1) 100%
);

$description-list-gradient: radial-gradient(85% 100% at 70% 70%,
  rgba(221, 240, 247, 0.5) 83.1%,
  rgba(201, 175, 255, 0.5) 100%);

$white: #fff;
$red: #f01e00;
$red-10: #fde8e5;
$blue: #57b2d9;
$blue-5: #f7fbfd;
$blue-10: #EEF7FB;
$blue-20: #ddf0f7;
$blue-header: #3992b5;
$button-link: #307f9e;
$link: #26657e;
$dark-brown: #4a4a4a;
$dark-navy-blue: #1a4657;
$red-extra: #e61f00;
$medium-grey: #818184;
$green: #96b92c;
$green-20: #eaf1d5;
$green-header: #718f16;
$green-button: #637d13;
$denim: #000;
$berry: red;
$youmo-green: #97b739;
$youmo-green-header: #718f16;
$youmo-link: #216682;
$ctaColor: $blue;
$black: #000;
$text-black: #1a1a1a;
$text-red: #cc1900;
$darker-grey: #333;
$dark-grey: #484848;
$black-70: #4d4d4d;
$black-60: #666666;
$black-05: #f2f2f2;
$black-20: #cccccc;
$black-30: #b3b3b3;
$medium-dark-grey: #5a5a5a;
$medium-grey: #818184;
$light-grey: #d8d8d8;
$gallery-grey: #ededed;
$burger-color: #4d4d4d;
$umo-darker-grey: #353535;

$colors: ('transparent': transparent,
  'grey-100': #ededed,
  'grey-200': #d8d8d8,
  'grey-300': #818184,
  'grey-400': #5a5a5a,
  'grey-500': #666666,
  'grey-600': #484848,
  'grey-700': #333333,
  'grey-800': #1a1a1a,
  'grey-900': #000000,
  'main': $red,
  'main-text': $text-red,
  'secondary-light': $blue,
  'secondary': $blue,
  'secondary-dark': $blue-header,
  'secondary-text': $white,
  'black': $black,
  'white': $white,
  'blue': $blue,
  'blue-5': $blue-5,
  'blue-10': $blue-10,
  'blue-20': $blue-20,
  'red-light': opaque(#fff, rgba($red, 0.6)),
  'red': $red,
  'red-5': #fef4f2,
  'red-10': $red-10,
  'red-extra': $red-extra,
  'red-dark': opaque(#000, rgba($red, 0.6)),
  'text': $text-black,
  'heading': $red,
  'background': $white,
  'cta': $link,
  'cta-dark': $dark-navy-blue,
  'cta-light': $button-link,
  'cta-text': white,
  'text-link': #9700cc,
  'link-hover': #490062,
  'nav': $red-extra,
  'nav-dark': #ba1500,
  'nav-light': #fde7e4,
  'muted': $dark-brown,
  'green': $green,
  'green-20': $green-20,
  'green-header': $green-header,
  'green-button': $green-button,
  'green-hover': #5a7212,
  'burger-btn': $burger-color,
  'black-05': $black-05,
  'black-10': #e5e5e5,
  'black-20': $black-20,
  'black-30': $black-30,
  'black-70': $black-70,
  'dark-grey': $dark-grey,
  'theme-article': $theme-article-gradient,
  'theme-faq': $theme-faq-gradient,
  'theme-forum': $theme-forum-gradient,
  'theme-office': $theme-office-gradient,
  'theme-story': $theme-story-gradient,
  'theme-podcast': $theme-podcast-gradient,
  'theme-shortstory': $theme-shortstory-gradient,
  'theme-tip': $theme-tip-gradient,
  'theme-support': $theme-support-gradient,
  'rainbow': $rainbow,
  'rainbow-v2': $rainbow-v2,
  'purple': #9700cc,
  'umo-purple': #faf2fc,
  'umo-purple-10': #f5e5fa,
  'umo-green-5': #fafbf4,
  'button-link': #307f9e,
);

@if (youmo==$globaltheme) {
  $colors: map-merge($colors,
      ('main': $green,
        'secondary': $green,
        'secondary-dark': shade-color($green, 1),
        'secondary-light': tint-color($green, 1),
        'link-hover': c('button-link'),
      ));
}

// Legacy start --------------------
$primary: c('main');
$secondary: c('secondary');

@function primary-color() {
  @return c('main');
}

@function secondary-color() {
  @return c('secondary');
}

$pale-red: c('red-light');
// Legacy end ---------------------

//Gradients
$grey-gradient: linear-gradient(c('grey-300'), c('grey-600'));
$dark-grey-gradient: linear-gradient(c('grey-500'), c('grey-600'));

$dark-gradient: linear-gradient(to top,
  c('grey-600'),
  c('grey-300') 94%,
  c('grey-300'));

//Borders
$border-color-light: rgba(c('black'), 0.1);
$border-color-medium: rgba(c('black'), 0.2);

@function rgba-to-rgb($rgba, $background: $white) {
  @return mix(rgb(red($rgba), green($rgba), blue($rgba)),
    $background,
    alpha($rgba) * 100%);
}

//Shadows
$umo-drop-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.30);

//Content widths
$collection-page-width: 1048px;
$collection-page-text-width: 720px;
