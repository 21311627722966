@import '../../platformui/SelfTest/styles';
@include SelfTest(
  $fontColor: c('grey-800'),
  $primaryBtnTextColor: c('white'),
  $primaryBtnBgColor: c('black-70'),
  $secondaryBtnTextColor: c('grey-700'),
  $secondaryBtnBgColor: c('white'),
  $secondaryBtnBorder: c('black-70'),
  $checkboxBgColor: c('white'),
  $checkboxBorderColor: c('purple'),
  $checkedFontelloIcon: 'E81d',
  $resultBgColor: c('black-05'),
  $okIconUrl: url('../../framework/images/correct.svg'),
  $notOkIconUrl: url('../../framework/images/error.svg'),
  $errorBgColor: c('white'),
  $validBgColor: c('white')
) {
  .c-self-test {
    &__question {
      margin-bottom: 50px;
    }
    &__result {
      padding: 20px;
      margin-bottom: 20px;
      &-heading {
        @include secondary-font-family();
      }
    }
    &__imageblock {
      .c-imageblock__text {
        @include secondary-font-family();
      }
    }
    &__result-text-container {
      p {
        font-size: 1rem;
      }
    }
    &__optionheading {
      @include secondary-font-family();
      font-size: 1.125rem;
      font-style: normal;
      font-weight: bold;
      line-height: 28px;
      justify-content: unset;

      .c-self-test__question--is-error & {
        justify-content: flex-start;
        border: 1px dashed c('red');
        .c-self-test__optionheading__text {
          order: 2;
          @include breakpoint(medium) {
            margin-left: 15px;
          }
        }
      }
      .c-self-test__question--is-valid & {
        justify-content: flex-start;
        padding: 10px;
        margin-bottom: 20px;
        border: 1px dashed c('green');
        .c-self-test__optionheading__text {
          order: 2;
          @include breakpoint(medium) {
            margin-left: 15px;
          }
        }
      }
    }

    &__optiongroup {
      display: flex;
      padding: 0;
      flex-direction: column;
      align-items: flex-start;
      gap: 20px;

      .c-self-test__label {
        @include secondary-font-family();
        margin-bottom: 0;
        font-size: 1rem;
        font-style: normal;
        font-weight: normal;
        line-height: normal;
        min-height: 40px;
        display: flex;
        padding: 7px 20px 7px 8px;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        @include breakpoint(medium) {
          align-self: unset;
        }
        border-radius: 30px;
        background: c('black-05');

        &:hover {
          @include breakpoint(medium) {
            background: c('umo-purple-10');
          }
          cursor: pointer;
        }

        &__checked {
          background: c('umo-purple-10');
        }
      }
    }
    &__button {
      font-size: 1.125rem;
      font-style: normal;
      font-weight: normal;
      line-height: normal;
      display: flex;
      padding: 15px 30px;
      margin-bottom: 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 30px;
      width: 100%;

      &:hover {
        cursor: pointer;
        @include breakpoint(medium) {
          background-color: c('button-link');
        }
      }

      @include breakpoint(medium) {
        display: inline-block;
        width: auto;
        margin-right: 20px;
        margin-bottom: 55px;
      }

      &--secondary {
        border: 2px solid c('black-70');
        margin-bottom: 60px;
        &:hover {
          border-color: c('button-link');
          color: c('white');
        }
      }
    }

    &__checkbox {
      flex: none;
      position: unset;
      appearance: none;
      background: c('white');
      width: 26px;
      height: 26px;
      border: 2px solid c('purple');
      border-radius: 50%;
      cursor: pointer;
      &:checked {
        background: c('purple');
        + .c-self-test__option-text:before {
          color: c('white');
          left: 15px;
          top: calc(50% - 5px);
        }
      }
    }

    &__option-text {
      &:before {
        color: c('white');
      }

      &:after {
        content: unset;
      }
    }
  }
}
