@import '../../platformui/FindCare/findcare-searchfield/autocomplete.scss';

@include FindCare-Autocomplete(
  $className: '.findcare-searchfield__autocomplete',
  $black: c('black'),
  $white: c('white'),
  $muted: red,
  $lightGrey: orange,
  $cta: green
) {
  padding: 0;
  top: 75px;
  border-radius: 20px;

  @include breakpoint(medium) {
    top: 85px;
  }

  .nearby {
    position: relative;
    text-align: left;
    padding-left: 25px;
    @include icon('e85c');

    button {
      padding: 0 0 0 3px;
    }

    &:before {
      position: absolute;
      color: #396291;
      font-size: 15px;
      height: 20px;
      left: 6px;
      top: 6px;
    }
  }

  &--is-open {
    padding: 15px 10px 10px 10px;
    &:after {
      .findcare-searchfield__toggle-suggestion {
        transform: rotate(180deg);
      }
    }
  }

  & > .caretype-footer {
    margin-top: 5px;
    padding-top: 5px;
    border-top: 1px solid rgba(c('black'), 0.2);
  }

  &__title {
    display: none;
  }

  ul {
    li:nth-child(1) {
      padding-right: 4rem;
    }
  }

  &__item {
    font-size: rem-calc(14);

    &:focus,
    &:hover {
      background-color: rgba(c('grey-300'), 0.2);
    }
    & > button {
      background-color: transparent;
      width: 100%;
      display: block;
      font-size: inherit;
      text-align: left;
      min-height: 30px;
      padding: 10px;
      @include focus-handler($offset: -2px);
    }
  }
  .findcare-autocomplete__sublabel {
    margin-top: 4px;
    display: block;
    font-size: 14px;
    font-style: italic;
    color: c('grey-300');
  }
  &__close {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    margin-right: 10px;
    position: absolute;
    top: 20px;
    right: 0;
    background: transparent;
    font-size: 12px;
    color: black;
    cursor: pointer;

    @include icon('e81e');

    &:before {
      font-size: 17px;
      margin-left: 5px;
      padding-top: 2px;
    }
    @include breakpoint(medium) {
      top: 20px;
    }
  }

  .highlight {
    border: 2px solid red;
    border-radius: 6px;
    padding: 5px;
    margin-bottom: 15px;
    p {
      margin: 0;
    }
    button {
      width: 100%;
      text-align: left;
      background: none;
    }
  }
}
