@import '../../platformui/Shortcuts/styles.scss';

@include Shortcuts(
  $bgColor: c('black-05'),
  $itemBgColor: c('white'),
  $borderColor: c('black-70'),
  $txtColor: c('grey-700')
) {
  .c-shortcuts {
    &__heading {
      @include base-font-family();

      &--chapter-container {
        font-size: rem-calc(22);
        color: $red-extra;

        @include breakpoint(large) {
          font-size: rem-calc(26);
        }
      }

      &--isH3 {
        font-size: rem-calc(18);
        @include secondary-font-family();
      }
    }

    &__list {
      &__item {
        &__container {
          &__title {
            @include base-font-family();
          }
          &__description {
            @include secondary-font-family();
          }
        }
      }
    }
  }
}

.c-chapter-container-collection {
  .c-shortcuts {
    background-color: c('white');
    padding: rem-calc(10) 0 0 0;

    &__list {
      max-width: 100%;
      margin-bottom: 0;

      &__item {
        position: relative;
        height: auto;
        width: 100%;
        padding-right: rem-calc(40);
        margin-inline: 0;

        @include breakpoint(medium) {
          width: rem-calc(350);
        }
      }

      &__item__container {
        height: auto;
        &__title {
          font-size: rem-calc(18);

          &:after {
            position: absolute;
            top: 50%;
            right: rem-calc(10);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
          }

          @include breakpoint(medium) {
            font-size: rem-calc(20);
          }
        }

        &__img {
          min-width: rem-calc(40);
        }
      }
    }
  }
}
