@import '../../platformui/SubjectIntro/styles';

@include SubjectIntro(
  $backgroundColor: transparent,
  $subjectTextColor: c('grey-700'),
  $linkListColor: c('black-70'),
  $linkListHoverColor: rgb(123, 123, 128)
) {
  .subject-intro {
    padding-bottom: 20px;
    @include breakpoint(large) {
      padding: 0;

      [dir='rtl'] & {
        > div {
          display: flex;
        }
      }
    }

    @include breakpoint(large) {
      background-color: c('umo-purple');
      @include site-theme(youmo) {
        background-color: c('umo-green-5');
      }
    }

    &__text {
      color: c('grey-800');
      background-color: c('umo-purple');

      @include site-theme(youmo) {
        background-color: c('umo-green-5');
      }

      &:before {
        content: '';
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg width='320' height='50' viewBox='0 0 320 50' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 50.0004H320V13.2172C320 13.2172 253.305 47.0355 196.773 37.9047C170.176 33.6078 149.324 25.4945 129.758 17.8812L129.723 17.8695C107.719 9.30702 87.3398 1.37538 62.2344 0.209364C31.7539 -1.20665 16.1719 4.78944 0 11.2758V50.0004Z' fill='white'/%3E%3Cpath d='M0 50.0004H320V13.2172C320 13.2172 253.305 47.0355 196.773 37.9047C170.176 33.6078 149.324 25.4945 129.758 17.8812L129.723 17.8695C107.719 9.30702 87.3398 1.37538 62.2344 0.209364C31.7539 -1.20665 16.1719 4.78944 0 11.2758V50.0004Z' fill='url(%23paint0_linear_6403_28388)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_6403_28388' x1='-55.9715' y1='3.81155' x2='-56.0864' y2='49.5265' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.125' stop-color='%23FCD2CC'/%3E%3Cstop offset='0.828125' stop-color='%23FAF2FC'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
        @include site-theme(youmo) {
          background-image: url("data:image/svg+xml,%3Csvg width='320' height='51' viewBox='0 0 320 51' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 50.5001H320V13.7169C320 13.7169 253.305 47.5353 196.773 38.4044C170.176 34.1076 149.324 25.9943 129.758 18.381L129.723 18.3693C107.719 9.80678 87.3398 1.87514 62.2344 0.70912C31.7539 -0.706896 16.1719 5.2892 0 11.7755V50.5001Z' fill='%23FAFBF4'/%3E%3C/svg%3E");
        }
        @include breakpoint(medium) {
          content: none;
        }
        background-repeat: no-repeat;
        background-size: contain;
        background-position-y: bottom;
        width: 100%;
        height: 100px;
        top: -90px;

        @include breakpoint(medium) {
          height: 160px;
          top: -150px;
        }
      }

      @include breakpoint(large) {
        content: none;
        &__content {
          padding-bottom: 0px;
        }
      }

      &:after {
        background-image: url("data:image/svg+xml,%3Csvg width='320' height='77' viewBox='0 0 320 77' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0.000366211V29.5364C0 29.5364 54.7228 4.63548 101.107 13.8981C124.433 18.5561 138.838 33.8909 152.346 48.2703C165.699 62.4851 178.174 75.7662 197.524 76.877C222.817 78.3287 239.036 66.733 255.927 54.6563C273.145 42.3462 291.061 29.5364 320 29.5364V0.000366211H0Z' fill='white'/%3E%3Cpath d='M0 0.000366211V29.5364C0 29.5364 54.7228 4.63548 101.107 13.8981C124.433 18.5561 138.838 33.8909 152.346 48.2703C165.699 62.4851 178.174 75.7662 197.524 76.877C222.817 78.3287 239.036 66.733 255.927 54.6563C273.145 42.3462 291.061 29.5364 320 29.5364V0.000366211H0Z' fill='url(%23paint0_linear_6403_28396)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_6403_28396' x1='46.5' y1='0.000365779' x2='47.0186' y2='128.752' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0.152565' stop-color='%23FAF2FC'/%3E%3Cstop offset='0.881993' stop-color='%23D599EB'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
        @include site-theme(youmo) {
          background-image: url("data:image/svg+xml,%3Csvg width='320' height='78' viewBox='0 0 320 78' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0.500122V30.0361C0 30.0361 54.7228 5.13523 101.107 14.3979C124.433 19.0559 138.838 34.3907 152.346 48.7701C165.699 62.9848 178.174 76.266 197.524 77.3767C222.817 78.8285 239.036 67.2327 255.927 55.1561C273.145 42.846 291.061 30.0361 320 30.0361V0.500122H0Z' fill='%23FAFBF4'/%3E%3C/svg%3E");
        }
        background-repeat: no-repeat;
        background-size: contain;
        height: 100px;
        bottom: -50px;

        @include breakpoint(medium) {
          height: 280px;
          bottom: -220px;
        }
        @include breakpoint(large) {
          content: '';
          background-image: url("data:image/svg+xml,%3Csvg width='100' height='496' viewBox='0 0 100 496' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M100 0L38.0546 0.00151253C38.0546 88.6823 -4.02739 88.831 0.314244 167.837C3.623 228.048 43.2227 266.849 56.9399 338.601C70.6572 410.352 19.8546 495.001 19.8546 495.001L100 495V0Z' fill='url(%23paint0_radial_6396_5408)'/%3E%3Cdefs%3E%3CradialGradient id='paint0_radial_6396_5408' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(-22.5 163.48) scale(123 502.509)'%3E%3Cstop stop-color='%23F9A599'/%3E%3Cstop offset='0.635417' stop-color='%23FDE8E5'/%3E%3Cstop offset='0.857125' stop-color='%23FAF2FC'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E");
          @include site-theme(youmo) {
            background-image: url("data:image/svg+xml,%3Csvg width='100' height='496' viewBox='0 0 100 496' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M100 0.0205078L38.0546 0.0220203C38.0546 88.7028 -4.02739 88.8515 0.314244 167.858C3.623 228.069 43.2227 266.87 56.9399 338.621C70.6572 410.372 19.8546 495.022 19.8546 495.022L100 495.021V0.0205078Z' fill='%23FAFBF4'/%3E%3C/svg%3E");
          }
          left: -99px;
          [dir='rtl'] & {
            right: -99px;
            transform: rotate(180deg);
          }
          width: 100px;
          top: 0;
          height: 100%;
          bottom: unset;
        }
      }
    }
    &__image-container {
      [dir='rtl'] & {
        float: left;
      }

      &__wrapper {
        background-color: transparent;
      }

      @include breakpoint(xxlarge) {
        &:after {
          content: '';
          background: none;
          background-image: url("data:image/svg+xml,%3Csvg width='101' height='495' viewBox='0 0 101 495' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M79.3164 0H0.5V495H61.1618C61.3938 494.612 111.807 410.187 98.1554 338.601C91.7131 304.82 79.548 278.343 68.1323 253.497C55.3005 225.569 43.4154 199.701 41.6687 167.837C39.5065 128.394 48.8865 108.606 58.823 87.6431C68.7898 66.6167 79.3164 44.4093 79.3164 0.00151253V0Z' fill='url(%23paint0_radial_6396_5409)'/%3E%3Cdefs%3E%3CradialGradient id='paint0_radial_6396_5409' cx='0' cy='0' r='1' gradientUnits='userSpaceOnUse' gradientTransform='translate(119.5 377.979) rotate(-179.037) scale(119.017 281.697)'%3E%3Cstop stop-color='%23D599EB'/%3E%3Cstop offset='0.617695' stop-color='%23F5E5FA'/%3E%3Cstop offset='0.929391' stop-color='%23FAF2FC'/%3E%3C/radialGradient%3E%3C/defs%3E%3C/svg%3E");
          left: -5px;
          @include site-theme(youmo) {
            background-image: url("data:image/svg+xml,%3Csvg width='101' height='496' viewBox='0 0 101 496' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M79.3164 0.0205078H0.5V495.021H61.1618C61.3938 494.632 111.807 410.207 98.1554 338.621C91.7131 304.841 79.548 278.364 68.1323 253.517C55.3005 225.589 43.4154 199.722 41.6687 167.858C39.5065 128.415 48.8865 108.626 58.823 87.6636C68.7898 66.6372 79.3164 44.4298 79.3164 0.0220203V0.0205078Z' fill='%23FAFBF4'/%3E%3C/svg%3E");
          }
          background-repeat: no-repeat;
          [dir='rtl'] & {
            transform: rotate(180deg);
            left: 5px;
          }
          width: 100%;
        }
        &:before {
          content: none;
        }
      }
    }

    &__subject-heading {
      font-size: 2.5rem;
      @include breakpoint(large) {
        font-size: 3.125rem;
      }
    }
    .subject-intro-linklist__list {
      display: block;

      li {
        margin-bottom: 20px;
        [dir='rtl'] & {
          margin-right: 0;
          margin-left: 10px;
        }
      }

      .subject-intro-linklist__link {
        text-transform: unset;
        padding: 15px 30px;
        height: 100%;
        display: flex;

        @include breakpoint(large) {
          display: inline-block;
        }

        span {
          padding: 0;
          @include base-font-family();
          font-weight: normal;
          font-size: 1.125rem;
          line-height: normal;
          @include breakpoint(large) {
            font-size: 1.25rem;
            height: 20px;
          }
        }
      }
    }
  }
}
