@import '../../platformui/FindCare/findcare-map/styles.scss';

@include FindCare-map($cta: c('nav')) {
  .findcare-map {
    .pwt-marker-cluster {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $red-extra;
      border-radius: 50%;

      @include focus-handler(
        $color: c('black'),
        $offset: 1px,
        $outline: 2px,
        $focusWithin: true
      );

      div {
        outline: none;
      }
    }

    //Overlayshadow with text fix
    .gm-style-pbc {
      z-index: 999 !important;
      pointer-events: none;
    }

    &__map__controls__boundaries {
      @include subheading-font-family();
      font-weight: normal;
      text-transform: unset;
      font-size: 16px;
      @include focus-handler();
    }
    &__map-modal-trigger {
      &:before {
        color: $blue;
        font-size: 24px;
      }
      @include icon('e81b');
      @include subheading-font-family();
      border: solid 1px c('grey-300');
      color: c('grey-600');
      height: 50px;
      font-size: 20px;
      text-transform: none;
    }
    &__map__controls__zoom-out {
      &:before {
        left: 14px;
        width: 13px;
        height: 3px;
        top: 18px;
      }
    }
    &__map__controls__zoom-in {
      &:before {
        left: 14px;
        width: 13px;
        height: 3px;
        top: 18px;
      }
      &:after {
        left: 19px;
        height: 13px;
        width: 3px;
        top: 13px;
      }
    }

    &__map__popup {
      &__phone {
        text-decoration: underline;
      }
      &__close {
        &:before,
        &:after {
          height: 3px;
          background-color: c('grey-300');
        }
      }

      &__wrapper {
        padding: 0;
        margin-right: 30px;
        border: none;
      }

      &__heading {
        margin: 0 0 4px 0;
        a {
          color: c('black');
        }
      }

      &__content {
        padding: 20px;
        border-radius: 20px;
        &__inner {
          margin: 0;

          .findcare-search-list-item__list {
            &--info-popup {
              border-top: 0;
              margin: 0;
              padding-top: 15px;
              .findcare-search-list-item__list__item__title {
                @include icon('E81D');
                &:before {
                  font-size: 16px;
                  color: $red;
                  margin-right: 4px;
                  top: 2px;
                }
              }
            }
            &__item__reception-link {
              text-decoration: underline;
              margin: 1px;
              a {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'%3E%3Cpath fill='%2357B2D9' fill-rule='evenodd' d='M0 12.02C.944 16.576 5.016 20 9.897 20 15.477 20 20 15.523 20 10S15.477 0 9.897 0C5.017 0 .944 3.425 0 7.98h7.69L6.551 6.907c-.491-.492-.816-.984-.816-1.694.002-1.114.935-1.949 2.128-1.95.843.004 1.426.52 1.917 1.057l5.456 5.69-5.545 5.763c-.468.511-1.053.963-1.824.961-1.238-.002-2.148-.905-2.145-2.039 0-.602.513-1.182.96-1.587l1.05-1.088H0z'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: center left;
                padding: 2px 0 2px 25px;
              }
            }
          }
        }
      }
    }

    .findcare-map__map__popup__scroll {
      display: none;
    }
  }
}
