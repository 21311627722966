@import '../../platformui/Meganav/styles';

@include Meganav(
  $width: $global-width,
  $bgColor: c('white'),
  $boxShadow: 0 3px 10px 3px rgba(c('black'), 0.2),
  $itemSelectedBorderColor: c('black')
) {
  @include site-theme(umo) {
    padding: 50px 0 50px;
    box-shadow: 0 5px 5px 0px rgb(0 0 0 / 20%);

    &:before {
      content: '';
      box-shadow: 0 0px 5px 2px rgb(0 0 0 / 20%);
      position: absolute;
      top: 0px;
      width: 100%;
    }
  }

  @include site-theme(youmo) {
    padding: 50px 20px 50px 35px;

    [dir='rtl'] & {
      padding: 50px 35px 50px 20px;
    }

    &:before {
      background: rgba-to-rgb(rgba(c('black'), 0.07));
      bottom: 20px;
      content: '';
      left: 50%;
      position: absolute;
      top: 20px;
      transform: translateX(-50%);
      width: 100%;
      min-height: 132px;
      border-left: 20px solid c('white');
      border-right: 20px solid c('white');

      [dir='rtl'] & {
        text-align: right;
      }
    }
  }

  &__container {
    @include site-theme(umo) {
      padding-left: 10px;
    }
  }
  @include site-theme(umo) {
    &__list {
      margin-bottom: 40px;
      &__item {
        border-bottom: 1px solid c('black-20');
        a {
          @include base-font-family();
          color: c('grey-600');
          margin: 8px 0 8px;
          padding: 0;
          line-height: 28px;
        }
        span {
          &:before {
            content: '\e808';
            color: c('black-70');
            font-family: 'fontello';
            margin-right: 10px;
            display: inline-block;
          }
        }
      }
    }
  }

  @include site-theme(youmo) {
    &__list {
      &__item {
        a {
          @include base-font-family();
          @include default(c('black'));
          @include fancy-hover(25px, 3px, -6px);
          margin-bottom: 8px;

          span {
            display: inline;
          }
        }
      }
      &--is-selected {
        a {
          span {
            &:after {
              border-color: $red;
            }
          }
        }
      }
    }
  }
}
