@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

$backgroundColor: $blue-5;
$backgroundColorChecked: linear-gradient(90deg, rgba(230, 31, 0, 0.5) 0%, rgba(151, 0, 204, 0.5) 100%),
#fff;
$backgroundColorHover: linear-gradient(93deg, rgba(230, 31, 0, 0.08) 0%, rgba(151, 0, 204, 0.08) 103.2%);
$boxPadding: 10px;
$fontColor: black;
$checkedIconUrl: url('../../framework/images/umo/checklist_icon_checked.svg');
$uncheckedIconUrl: url('../../framework/images/umo/checklist_icon_unchecked.svg');

.c-checkbox-with-img {
  $block: &;

  position: relative;
  padding: rem-calc(8.5);
  padding-right: rem-calc(15);
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: rem-calc(52);
  background: $backgroundColor;
  width: 100%;
  cursor: pointer;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.15);
  transition: all 0.3s ease;
  min-height: 80px;

  @include focus-handler($offset: 8px, $outline: 2px, $focusWithin: true);

  @include breakpoint(medium) {
    max-width: calc(100% / 2 - 10px);
  }

  @include breakpoint(large) {
    max-width: calc(100% / 3 - 14px);

    &:hover {
      background: $backgroundColorHover;
    }
  }

  &__img {
    width: 63px;
    height: 63px;
    border-radius: 50%;
    position: relative;
    z-index: 1;

    img {
      object-fit: cover;
      height: 100%;
    }
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    border-radius: inherit;
    background: transparent;
    transition: background 0.3s ease;
  }

  &::before {
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
  }

  &::after {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:has(#{$block}__input:checked) {
    &::before {
      background: $backgroundColorChecked;

      transform: rotate(180deg);
      opacity: 0.15;
    }

    &::after {
      background: rgb(252, 222, 217);
      background: linear-gradient(141deg, rgba(252, 222, 217, 1) 0%, rgba(240, 217, 248, 1) 100%);

    }
  }

  &:has(#{$block}__input:disabled) {
    &:focus-within {
      cursor: not-allowed;
    }

    pointer-events: none;
  }

  &__input {
    @include visually-hidden;

    &:checked {
      +#{$block}__icon {
        &::before {
          content: '';
          display: block;
          width: 37px;
          height: 37px;
          background-image: $checkedIconUrl;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
    }

    &:disabled {
      &:focus-within {
        cursor: default;
      }
    }
  }

  &__icon {
    z-index: 1;

    &::before {
      content: '';
      display: block;
      width: 37px;
      height: 37px;
      background-image: $uncheckedIconUrl;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &__text {
    z-index: 1;
    color: $fontColor;
    @include secondary-font-family();
    font-size: rem-calc(16);
    font-weight: 400;
    width: 142px;
    word-break: normal;
    flex-grow: 1;

    &:first-child {
      padding-left: rem-calc(15);
    }
  }
}
