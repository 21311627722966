@import '../../platformui/VideoContainer/styles';

@include VideoContainer(
  $blockBackground: c('rainbow'),
  $headingColor: c('text'),
  $headerSize: (
    "default": (
      "landscape": 22px,
    ),
    "large": (
      "landscape": 26px,
      "standing": 32px,
    ),
  ),
  $descriptionColor: c('text'),
  $linkColor: c('text'),
  $iconColor: c('grey-600'),
  $iconContent: '\e808',
);
