.c-letter-slides-container {
  .c-section__inner {
    border: none;
    padding: 0;
  }

  .letter-slides {
    position: relative;
    margin-inline: auto;
    margin-block: rem-calc(20);

    p {
      white-space: pre-line;
      max-width: $collection-page-text-width;
    }

    @include breakpoint(medium) {
      max-width: calc(2 * 100% / 3);
    }

    @include breakpoint(large) {
      max-width: rem-calc(1048);
      margin-block: rem-calc(50);
    }

    .button {
      @include base-font-family();
      display: flex;
      padding: 0.9375rem 1.875rem;
      justify-content: center;
      align-items: center;
      border-radius: 1.875rem;
      transition: background-color 0.3s ease;
      font-size: 1.125rem;
      cursor: pointer;

      @include breakpoint(large) {
        font-size: 1.25rem;
      }

      &:hover {
        background-color: c('purple');
        color: c('white');
        border-color: c('purple');
      }

      &:disabled {
        display: none;
      }

      &--primary {
        background: c('black-70');
        border: 2px solid c('black-70');
        color: c('white');
      }

      &--secondary {
        background: c('white');
        color: c('black-70');
        border: 2px solid c('black-70');
      }
    }

    &__modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99991;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &__modal-content {
      position: relative;
      top: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      display: flex;
      padding: 1.25rem;
      flex-direction: column;
      gap: rem-calc(10);
      align-items: flex-start;
      align-self: stretch;
      background-color: c('blue-10');
      background-position: right;
      background-size: cover;
      background-repeat: no-repeat;
      overflow: auto;

      @include breakpoint('large') {
        max-width: rem-calc(1048);
        max-height: rem-calc(710);
        top: 10%;
        padding: rem-calc(60) rem-calc(25) rem-calc(40);
        background-position: center;
        border-radius: rem-calc(10);
        overflow: hidden;
      }

      @for $i from 0 through 5 {
        &--gradient-#{$i} {
          background-image: url('./background-svgs/gradient-#{$i}.svg');
        }
      }

      &__top {
        display: grid;
        grid-template-columns: 20px 1fr 20px;
        grid-template-rows: repeat(1, 1fr);
        gap: 0.625rem;
        width: 100%;

        @include breakpoint('large') {
          position: absolute;
          padding-block: 1.0625rem;
          padding-inline: 1.375rem;
          top: 0;
          left: 0;
        }
      }

      &__inner {
        flex-grow: 1;
        display: flex;
        width: 100%;
      }

      &__bottom {
        display: flex;
        width: 100%;
        flex-direction: column;
        gap: 0.625rem;

        .button--primary,
        .button--secondary,
        .c-share-button {
          padding: rem-calc(10) rem-calc(40);
        }

        @include breakpoint(medium) {
          flex-direction: row;
          justify-content: space-between;
          padding-inline: 2rem;

          .button--primary,
          .button--secondary,
          .c-share-button {
            padding: 0.9375rem 1.875rem;
          }
        }

        &--first {
          justify-content: flex-end;
        }

        &--last {
          @include breakpoint(large) {
            padding-right: rem-calc(190);
          }
        }
      }
    }

    &__pagination {
      grid-column-start: 2;
      grid-row-start: 2;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: 1rem;
      @include secondary-font-family();

      @include breakpoint('large') {
        grid-row-start: 1;
      }
    }

    &__close-modal {
      grid-column-start: 3;
      grid-row-start: 1;
      padding-right: 1.375rem;
      width: 1.25rem;
      height: 1.25rem;
      background-image: url('../../framework/images/umo/close.svg');
      background-position: right;
      background-repeat: no-repeat;
      background-size: contain;
      cursor: pointer;
    }
  }
}
