$base-font: 'Aleo', arial, sans-serif;
$secondary-font: 'Lato', arial, sans-serif;

// Webfont for tigrinya
body,
title {
  :lang(ti-ER) {
    font-family: 'Noto Sans Ethiopic', 'Lato', serif;
  }

  :lang(ar-SY) & {
    font-family: 'Arial', serif;
  }
}

// Base fonts
@mixin base-font-family() {
  font-family: $base-font;

  :lang(ti-ER) & {
    font-family: 'Noto Sans Ethiopic', 'Aleo', serif;
  }

  :lang(ar-SY) & {
    font-family: 'Arial', serif;
  }
}

@mixin secondary-font-family() {
  font-family: $secondary-font;

  :lang(ti-ER) & {
    font-family: 'Noto Sans Ethiopic', 'Aleo', serif;
  }

  :lang(ar-SY) & {
    font-family: 'Arial', serif;
  }
}

@mixin subheading-medium-font-family() {
  font-family: $secondary-font;

  :lang(ti-ER) & {
    font-family: 'Noto Sans Ethiopic', 'Lato', serif;
  }

  :lang(ar-SY) & {
    font-family: 'Arial', serif;
  }
}

@mixin subheading-font-family() {
  @include site-theme(umo) {
    font-family: $secondary-font;
    font-weight: normal;
  }

  :lang(ar-SY) & {
    font-family: 'Arial', serif;
  }
}

@mixin meta-light-italic-font-family() {
  font-family: $base-font;
  font-style: italic;
  font-weight: 200;

  :lang(ti-ER) & {
    font-family: 'Noto Sans Ethiopic', 'Aleo', serif;
  }

  :lang(ar-SY) & {
    font-family: 'Arial', serif;
  }
}

// ******************
// Global font mixins
// ******************
@mixin text-responsive(
  $breakpoint,
  $fontsize,
  $lineheight,
  $letterspacing: normal,
  $marginbottom: false
) {
  @if $editor == 'true' {
    @if $breakpoint == large {
      font-size: rem-calc($fontsize);
      @if $letterspacing != normal {
        letter-spacing: rem-calc($letterspacing);
      }
      line-height: $lineheight;
    }
  } @else {
    @if $breakpoint == small {
      font-size: rem-calc($fontsize);
      @if $letterspacing != normal {
        letter-spacing: rem-calc($letterspacing);
      }
      line-height: $lineheight;
    } @else {
      @include breakpoint($breakpoint) {
        font-size: rem-calc($fontsize);
        @if $letterspacing != normal {
          letter-spacing: rem-calc($letterspacing);
        }
        line-height: $lineheight;
      }
    }
  }
}

// ******************
// Headings
// ******************
@mixin heading() {
  font-weight: bold;

  & > a {
    color: inherit;
  }
}

@mixin apply-margins($breakpoint, $marginbottom: false) {
  @if $editor == 'true' {
    @if $breakpoint == large {
      @if $marginbottom != false {
        margin: 0 0 rem-calc($marginbottom);
      }
    }
  } @else {
    @if $breakpoint == small {
      @if $marginbottom != false {
        margin: 0 0 rem-calc($marginbottom);
      }
    } @else {
      @include breakpoint($breakpoint) {
        @if $marginbottom != false {
          margin: 0 0 rem-calc($marginbottom);
        }
      }
    }
  }
}

@mixin h1($applymargins: false) {
  @include text-responsive(small, 40, 1.1);
  @include text-responsive(large, 50, 1.1);

  @if $applymargins == true {
    @include apply-margins(small, 10px);
    //@include apply-margins(large, 30px);
  }
}

@mixin h2($applymargins: false) {
  @include text-responsive(small, 22, 1.17);
  @include text-responsive(large, 26, 1.17);

  @if $applymargins == true {
    @include apply-margins(small, 8);
    @include apply-margins(medium, 8);
    @include apply-margins(large, 10);
  }
}

@mixin h3($applymargins: false) {
  @include text-responsive(small, 18, 1.27);
  @include text-responsive(medium, 18, 1.27);
  @include text-responsive(large, 18, 1.27);

  @if $applymargins == true {
    @include apply-margins(small, 6);
    @if $applymargins == true {
      @include apply-margins(large, 6);
    }
  }
}

// ***************
// Font for entries on start page
// ***************

@mixin EntryFont {
  @include secondary-font-family();
  line-height: 1.4;
  color: c('grey-600');
}

// ***************
// Preamble
// ***************
@mixin lead($color: c('black'), $applymargins: true) {
  @include xlarge-text(c('grey-600'), 1.4);

  @if $applymargins == true {
    @include apply-margins(small, 30);
  }
  font-weight: 400;

  @media print {
    font-size: 18pt;
  }

  //For tinymce based preambles
  p {
    font-size: inherit;
    line-height: inherit;
  }
}

// ***************
// Texts
// ***************

@mixin show-for-sr() {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}

@mixin paragraph() {
  @include default();

  @media print {
    font-size: 14pt;
  }
}

@mixin section-header($color: c('black')) {
  @include text-responsive(small, 22, 1.18);
  @include text-responsive(large, 30, 1.18);
  color: $color;
}

@mixin topnavigation-text() {
  @include text-responsive(small, 16, 1.05);
  @include text-responsive(medium, 18, 1.05);
  @include text-responsive(large, 16, 1.05);
  @include text-responsive(xlarge, 18, 1.05);
  @include text-responsive(xxlarge, 20, 1.05);
}

@mixin xxxsmall-text($color: c('text'), $lineheight: 1) {
  @include text-responsive(small, 11, $lineheight);
  color: $color;
}

@mixin xxsmall-text($color: c('text'), $lineheight: 1) {
  @include text-responsive(small, 12, $lineheight);
  color: $color;
}
@mixin xsmall-text($color: c('text'), $line-height: 1) {
  @include text-responsive(small, 14, $line-height);
  color: $color;
}

@mixin small-text($color: c('text')) {
  @include text-responsive(small, 14, 1.3);
  @include text-responsive(large, 16, 1.3);
  color: $color;
}

@mixin medium-text($color: c('text')) {
  @include text-responsive(small, 16, 1.3);
  color: $color;
}

@mixin xmedium-text($color: c('text')) {
  @include text-responsive(small, 18, 1.27);
  color: $color;
}

@mixin large-text($color: c('text'), $line-height: 1.1) {
  @include text-responsive(small, 16, $line-height);
  @include text-responsive(medium, 18, $line-height);
  @include text-responsive(large, 20, $line-height);
  color: $color;
}

@mixin xlarge-text($color: c('black'), $line-height: 1.2) {
  @include text-responsive(small, 20, $line-height);
  @include text-responsive(large, 24, $line-height);
  color: $color;
}

@mixin xxlarge-text($color: c('black')) {
  @include text-responsive(small, 18, 1.4);
  @include text-responsive(large, 28, 1.4);
}

@mixin xxxlarge-text() {
  @include text-responsive(small, 30, 1.15);
  @include text-responsive(large, 40, 1.15);
}

@mixin default($color: inherit, $line-height: 1.55) {
  @include text-responsive(small, 16, $line-height);
  @include text-responsive(large, 18, $line-height);
  color: $color;
}

@mixin button-text($color: c('cta')) {
  @include text-responsive(small, 20, 1.5);
}

@mixin meta-text() {
  @include text-responsive(small, 22, 1.3);
  @include text-responsive(large, 26, 1.3);
}

// ***************
// Focus mixins
// ***************
@mixin focus-handler(
  $color: c('grey-600'),
  $offset: 4px,
  $outline: 2px,
  $focusWithin: false
) {
  @if ($focusWithin) {
    &:focus-within {
      @include focus-style($color, $offset, $outline);
    }
  } @else {
    &:focus {
      @include focus-style($color, $offset);
    }
  }
}

@mixin focus-within-handler($color: $black, $offset: 4px) {
  &:focus-within {
    @include focus-style($color, $offset);
  }
}

@mixin focus-style($color: c('grey-600'), $offset: 4px, $outline: 2px) {
  outline: $outline solid $color;
  outline-offset: $offset;
  text-decoration: none;
}

// ***************
// Fancy hover
// ***************
@mixin fancy-hover($borderWidth: 35px, $borderHeight: 3px, $bottomPos: -8px) {
  & > span {
    display: inline-block;
    position: relative;

    &:after {
      border-bottom: solid $borderHeight rgba(c('black'), 0.2);
      bottom: $bottomPos;
      content: ' ';
      display: block;
      position: absolute;
      transition: all ease 0.2s;
      width: $borderWidth;

      [dir='rtl'] & {
        left: auto;
        right: 0;
      }
    }
  }

  &:hover {
    text-decoration: none;

    span {
      &:after {
        border-color: $red;
        width: 100%;
      }
    }
  }
}

@mixin iconbase() {
  &:before {
    font-family: 'fontello';
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: 0.2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */

    [dir='rtl'] & {
      margin-left: 0.2em;
      margin-right: 0;
    }

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  }
}

@function ununicodeIcon($character) {
  @return unquote('"') + unquote(str-insert($character, '\\', 1)) + unquote('"');
}

@mixin icon($content, $rtl-support: true, $include-base: true) {
  @if ($include-base == true) {
    @include iconbase();
  }

  &:before {
    content: ununicodeIcon($content);

    @if ($content == '\e808'or $content == '\e809'or $content == '\e80e') {
      [dir='rtl'] & {
        transform: scaleX(-1);
        filter: FlipH;
      }
    }

    @if $rtl-support == false {
      [dir='rtl'] & {
        transform: scaleX(1);
        filter: none;
      }
    }

    @content;
  }
}

@mixin content-lists-common {
  @include default(inherit, 1.3);
  list-style: none;

  @if $editor == 'true' {
    margin: 0 0 rem-calc(25);
    padding-left: rem-calc(10);

    [dir='rtl'] & {
      padding-left: 0;
      padding-right: rem-calc(10);
    }
  } @else {
    margin: 0 0 rem-calc(20);
    padding-left: 10px;

    [dir='rtl'] & {
      padding-right: 10px;
    }

    @include breakpoint(medium) {
      margin: 0 0 rem-calc(25);
      padding-left: rem-calc(25);

      [dir='rtl'] & {
        padding-right: rem-calc(25);
      }
    }
  }

  li {
    font-size: 1rem;
    @if $editor == 'true' {
      margin-bottom: rem-calc(10);
    } @else {
      margin-bottom: rem-calc(10);
    }
  }
}

@mixin ol-list() {
  list-style: decimal;
  overflow: hidden;
  @if $editor == 'true' {
    padding-left: rem-calc(33);
    [dir='rtl'] & {
      padding-left: 0;
      padding-right: rem-calc(33);
    }
  } @else {
    padding-left: rem-calc(20);

    [dir='rtl'] & {
      padding-left: 0;
      padding-right: rem-calc(20);
    }

    @include breakpoint(medium) {
      padding-left: rem-calc(33);

      @include site-theme(umo) {
        padding-left: 1.15rem;
      }

      [dir='rtl'] & {
        padding-left: 0;
        padding-right: rem-calc(33);
      }
    }
    @include site-theme(umo) {
      font-family: 'Lato';
      padding-left: 1.15rem;
    }
  }
  li {
    padding-left: rem-calc(5);

    @include breakpoint(medium) {
      padding-left: rem-calc(7);
    }

    [dir='rtl'] & {
      padding-left: 0;
      padding-right: rem-calc(5);

      @include breakpoint(medium) {
        padding-right: rem-calc(7);
      }
    }
    @include site-theme(umo) {
      &::marker {
        font-weight: bold;
      }
    }
  }
}

@mixin ul-list($bulletColor: $blue) {
  overflow: hidden;
  @include site-theme(umo) {
    @include breakpoint(medium) {
      padding-left: 1.05rem;
    }
  }
  li {
    padding-left: rem-calc(15);
    position: relative;

    @include site-theme(umo) {
      line-height: 26px;
      margin-bottom: 0;
    }

    [dir='rtl'] & {
      padding-left: 0;
      padding-right: rem-calc(15);
    }

    &:before {
      background: $bulletColor;
      border-radius: 50%;
      content: '\200B';
      display: block;
      height: rem-calc(8);
      left: 0;
      margin-left: rem-calc(-25);
      margin-right: rem-calc(15);
      position: relative;
      top: rem-calc(16);
      width: rem-calc(8);

      [dir='rtl'] & {
        margin-left: 15px;
        margin-right: -25px;
      }
      @include site-theme(umo) {
        background: c('red');
      }
    }
  }
}
