.c-letter-slide__add-button {
  display: flex;
  flex-direction: column;
  max-width: rem-calc(295);
  font-family: $secondary-font;
  font-size: rem-calc(16);

  input {
    @include focus-handler();
    padding-inline: rem-calc(16);
    padding-block: rem-calc(12);
    border-radius: rem-calc(20);
    border: 1px solid c('grey-700');
    margin-bottom: rem-calc(8);
    width: 100%;

    @include breakpoint(medium) {
      width: rem-calc(295);
    }

    &::placeholder {
      font-style: normal;
    }
  }

  button {
    padding: rem-calc(5);
    place-self: start;
    text-decoration: underline;
    text-underline-offset: 2px;
    border-radius: rem-calc(20);
    cursor: pointer;

    &:before {
      content: '\e806';
      color: c('black-70');
      font-family: 'fontello';
      margin-right: rem-calc(8);
      display: inline-block;
    }
  }

  &--is-open {
    button {
      place-self: end;

      &:before {
        content: '\e816';
        font-size: rem-calc(12);
      }
    }
  }
}
