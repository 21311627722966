@mixin Shortcuts(
  $bgColor: #f2f2f2,
  $itemBgColor: white,
  $borderColor: black,
  $txtColor: black,
  $headingColor: #333333
) {
  .c-shortcuts {
    display: flex;
    padding: 30px 0px 60px 0px;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    background: $bgColor;

    @include breakpoint(large) {
      padding-bottom: 30px;
    }

    &__heading {
      color: $headingColor;
      font-size: 1.875rem;
      letter-spacing: 0.25px;

      &--chapter-container {
        margin-right: auto;
      }
    }

    &__list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 10px;
      padding: 0;

      @include breakpoint(large) {
        width: 1260px;
        gap: 20px;
      }

      [dir='rtl'] & {
        padding-right: 0;
      }

      &__item {
        display: flex;
        width: 350px;
        height: 60px;
        padding: 10px 12px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        @include breakpoint(large) {
          width: 406px;
          margin: 0;
        }

        border-radius: 50px;
        margin: 0 20px 0 20px;

        background: $itemBgColor;
        border: 1px solid $borderColor;

        a {
          &:hover {
            text-decoration: none;
          }
        }

        &__container {
          display: flex;
          height: 50px;
          align-items: center;
          gap: 10px;
          flex-shrink: 0;
          align-self: stretch;

          &__img {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            border-radius: 50%;
            width: 40px;
            height: 40px;
          }

          &__text {
            display: flex;
            flex-direction: column;
          }

          &__title {
            color: $txtColor;
            font-size: 1.25rem;
            font-weight: 400;
            line-height: 20px;
            @include icon('f105', $pseudo: after);
            [dir='rtl'] & {
              @include icon('f104', $pseudo: after);
            }
          }
          &__description {
            color: $txtColor;
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 20px;
          }
        }
      }
    }
  }
  @content;
}
