$globaltheme:youmo;
$editor: 'false';
$globaltheme: umo !default;
$global-article-margin-bottom: 90px 90px 90px;

@import './base/foundation-imports';
@import '../../platformui/Framework/site-theme';
@import './base/theme';
@import './base/font-base';
@import './base/font-book';
@import '../../platformui/Framework/blocks';
@import '../../platformui/Framework/utils';
@import '../../platformui/ReadingAid/styles';
@import './base/utils';
@import './base/globals';
@import './base/form-base';
@import './base/animations';
@import './base/umo-youmo-mixins';
@import '../../components/imports';
@import '../../platformui/Framework/print';
@import './base/tables';

@import '../../platformui/Framework/platform-mixins/CookieProPanel.scss';
@include site-theme(umo) {
  @include CookieProPanel(
    $focusColor: $black,
    $acceptBtnTextHoverColor: c('white'),
    $acceptBtnHoverColor: darken($button-link, 5%),
    $settingsBtnTextHoverColor: c('white'),
    $settingsBtnHoverColor: darken(c('cta'), 5%),
    $settingsBtnBorder: 1px solid c('cta'),
    $textTransform: unset,
    $btnFont: $base-font,
    $btnFontSize: 20px,
    $btnSize: 50px
  );
}
@include site-theme(youmo) {
  @include CookieProPanel(
    $focusColor: $black,
    $acceptBtnTextHoverColor: c('white'),
    $acceptBtnHoverColor: darken($green-header, 5%),
    $settingsBtnTextHoverColor: c('white'),
    $settingsBtnHoverColor: darken($green-header, 5%),
    $settingsBtnBorder: 1px solid c('green-header'),
    $textTransform: unset,
    $btnFont: $base-font,
    $btnFontSize: 20px,
    $btnSize: 50px
  );
}
.main,
.c-articlefooter,
.c-footer,
.c-alertlist {
  opacity: 1;
}

.main > .c-section:last-child:not(:first-child) {
  .c-section__inner {
    border-top: 1px solid c('black-70');
  }
}

.c-search {
  &__autocomplete {
    background-color: c('white');
    padding: 15px 20px 25px 20px;
    border: 1px solid c('black-30');
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
    left: 15px;
    li {
      display: block;
      &:last-child {
        border-left: none;
      }
    }
    &__item {
      width: 100%;

      a {
        font-style: normal;
        color: c('grey-800');

        &:hover,
        &:active,
        &:focus {
          background-color: c('black-05');
          text-decoration: underline;
          border-radius: 5px;
        }
      }
    }
  }
}

@include site-theme(youmo) {
  .c-header-toolsy {
    display: block;
  }
  .c-header__search {
    @include breakpoint(large) {
      display: block;
    }
  }
}

.c-buildinfo {
  display: block;
}

@media print {
  .c-header-tools,
  .c-header-toolsy {
    display: none;
  }
}
