@mixin ShareButton(
  $bgColor: black,
  $border: 2px solid black,
  $border-radius: rem-calc(30),
  $color: white,
  $hoverBgColor: grey,
  $hoverColor: lightblue,
  $fontSize: rem-calc(18),
  $inlinePadding: rem-calc(40)
) {
  .c-share-button {
    @include focus-handler();
    @include base-font-family();

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: rem-calc(8);
    padding: rem-calc(10) $inlinePadding;
    width: 100%;
    color: $color;
    border-radius: $border-radius;
    border: $border;
    font-size: $fontSize;
    background-color: $bgColor;
    transition: background-color 0.2s ease;
    word-wrap: break-word;

    @include breakpoint(medium) {
      width: auto;
    }

    &:hover {
      background-color: $hoverBgColor;
      border-color: $hoverBgColor;
    }

    &__icon {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url('resources/share-icon.svg') no-repeat center;
      background-size: contain;
      margin-bottom: rem-calc(2);
    }
  }

  @content;
}
