@import '../../platformui/SearchBlock/styles';
@import './findcare-searchfield-autocomplete.scss';
$query: '.findcare-form__field--q';

.findcare-search {
  position: relative;
  width: 100%;

  @include breakpoint(medium) {
    display: flex;
  }

  &__free-text {
    position: relative;

    @include breakpoint(medium) {
      width: 100%;
    }

    @include breakpoint(large) {
      margin-right: 10px;
    }
  }
}

@include SearchFieldQuery(
  $className: 'findcare-search',
  $searchFieldBg: c('grey-100'),
  $cta: c('cta'),
  $muted: c('muted'),
  $border: solid 1px c('grey-300'),
  $borderRadius: (
    10px 0 0 10px,
    10px,
  ),
  $searchFieldFocusBorder: 1px solid c('grey-300'),
  $searchFieldFocusShadow: inset 0 5px 10px 0 rgba(c('black'), 0.2),
  $paddingLeft: (
    0,
    0,
  )
) {
  .findcare-search__field__query {
    @include base-font-family;
    font-size: rem-calc(16);
    border-radius: 100px;
    border-right: solid 1px c('grey-300');
    width: 100%;

    &::-ms-clear {
      display: none;
    }

    #{$query} & {
      @include breakpoint(medium only) {
        width: 100%;
      }
    }

    input {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include focus-handler();

      &::placeholder {
        color: $dark-grey;
        font-style: italic;
        opacity: 1;
      }

      &:-ms-input-placeholder {
        color: $dark-grey;
        font-style: italic;
      }

      &::-ms-input-placeholder {
        color: $dark-grey;
        font-style: italic;
      }
    }

    &--is-nearby {
      cursor: pointer;
      padding-left: 44px;
      text-align: left;

      @include icon('e85c');
      position: relative;

      &:before {
        color: #000;
        font-size: 30px;
        left: 10px;
        position: absolute;
        top: 15px;
      }

      @include icon('e84e', $pseudo: after);

      &:after {
        color: c('black');
        font-size: 20px;
        right: 15px;
        opacity: 1;
        position: absolute;
        top: 20px;
        transition: opacity 0.2s ease;
      }

      .c-spinner {
        opacity: 0;
        transition: opacity 0.2s ease;
      }
    }

    &--is-loading {
      &:after {
        opacity: 0;
      }
      .c-spinner {
        opacity: 1;
      }
    }
  }
}

.findcare-searchfield {
  input {
    border: none;
    font-size: 16px;
    @include breakpoint(medium) {
      font-size: 20px;
    }
    @include subheading-font-family();
    font-style: italic;
    color: c('black');
    padding: 10px 80px 10px 20px;
    background-color: transparent;
    height: 100%;
    width: 100%;
  }
}
.findcare-searchfield__unit-wrapper {
  input {
    padding-right: 40px;
  }
}
