body.no-scroll {
  overflow: hidden;
}

@mixin ToggleIcon(
  $iconColor: #000,
  $iconWidth: 16px,
  $iconThikness: 4px,
  $iconRadius: 2px
) {
  &:after,
  &:before {
    background-color: $iconColor;
    border-radius: $iconRadius;
    content: '';
    position: absolute;
    transition: transform 0.25s ease-out;
  }

  &:before {
    top: 50%;
    left: 50%;
    width: $iconThikness;
    height: $iconWidth;
    margin-left: -1px;
    margin-top: ((($iconWidth - ($iconThikness * 0.5)) * 0.5) * -1);
  }

  &:after {
    top: 50%;
    left: 50%;
    width: $iconWidth;
    height: $iconThikness;
    margin-left: ((($iconWidth - ($iconThikness * 0.5)) * 0.5) * -1);
    margin-top: -1px;
  }

  @content;
}

@mixin paper($line-margin: 70px) {
  position: relative;
  z-index: 1;
  overflow: hidden;
  position: relative;

  &:before,
  &:after {
    @include breakpoint(medium) {
      border-left: solid 1px rgba(c('black'), 0.1);
      bottom: 0;
      content: '';
      display: block;
      left: 50%;
      max-width: rem-calc(1260);
      margin-left: $line-margin - 1;
      width: 100%;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      z-index: 1;
    }
  }

  &:after {
    @include breakpoint(medium) {
      border-left: solid 1px rgba(c('white'), 0.1);
      margin-left: $line-margin;
    }
  }
}

@mixin paper-theme($theme-color) {
  background: c('white');
  background: linear-gradient(0deg, rgba(c('black'), 0.1) 0%, $theme-color 8%) 0
    51px;
  background-size: 100% 25px;
}

@mixin visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.u-paper {
  @include paper();

  .theme--faq & {
    @include paper-theme(rgba($blue, 0.1));
    z-index: auto;
  }

  .theme--story & {
    @include paper-theme(rgba($green, 0.1));
    z-index: auto;
  }

  .theme--pod & {
    @include paper-theme(rgba(c('black'), 0.05));
    z-index: auto;
  }

  .theme--forum & {
    @include paper-theme(rgba($red, 0.1));
    z-index: auto;
  }
}

span[data-epi-property-name] {
  display: block;
}

.u-error {
  height: 100%;

  body {
    background: rgba(c('black'), 0.05);
    height: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.u-dark-theme {
  @include site-theme(youmo) {
    background-image: $dark-gradient;
    position: relative;
    overflow: hidden;

    &:before {
      background-image: url('../../../framework/images/youmo/navigation-bg.png');
      bottom: 0;
      content: '';
      left: 0;
      opacity: 0.2;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }

    &:after {
      clear: both;
      content: '';
      display: table;
    }

    & > div {
      position: relative;
      z-index: 2;
    }
  }
}

.u-article-main {
  margin-bottom: 0px;

  a {
    text-decoration: underline;

    &:focus {
      text-decoration: underline;
    }
  }

  @include breakpoint(medium) {
    margin-bottom: 90px;

    padding-bottom: 0;
  }
}

.u-search-page {
  margin-top: 55px;
}

.u-center-medium {
  clear: both;
  float: none;
  margin-right: auto;
  margin-left: auto;

  @include breakpoint(large) {
    clear: none;
    float: left;
    margin-right: 0;
    margin-left: 0;
    position: relative;
  }
}

.u-center-medium:last-child:not(:first-child) {
  float: none;

  @include breakpoint(large) {
    float: right;
  }
}

.sign {
  margin-bottom: 25px;
}

.main-wrapper {
  min-height: 100vh;
  position: relative;
}

@media print {
  @page :first {
    margin: 1cm 17% 2cm 17%;
  }

  @page {
    margin: 2cm 17%;
  }
}
