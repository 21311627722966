@mixin UmoYoumoExpandButton(
  $buttonBgColor: transparent,
  $bgSize: 85px,
  $buttonIconBg: transparent,
  $contentUrl: '',
  $right: 0,
  $top: 26px,
  $width: 20px,
  $height: 8px,
  $hasIcon: true
) {
  .c-expand-button {
    position: absolute;
    background-color: $buttonBgColor;
    top: 0;
    right: $right;
    width: 50px;
    height: 100%;
    cursor: pointer;
    overflow: hidden;
    outline: none;
    z-index: 2;
    color: c('white');
    font-size: 12px;
    @if $hasIcon == true {
      @include icon('\e804');
    }

    @if $hasIcon == false {
      &:before {
        content: $contentUrl;
      }
    }

    [dir='rtl'] & {
      right: auto;
      left: 0;
    }

    &:before {
      transition: transform 0.3s ease;
      transform-origin: center;
      display: block;
      position: absolute;
      top: $top;
      right: 9px;
      margin: 0;
      width: $width;
      height: $height;
      z-index: 666;
    }

    @if $hasIcon == true {
      &:after {
        content: '';
        display: block;
        position: absolute;
        background-color: $buttonIconBg;
        height: $bgSize;
        width: $bgSize;
        border-radius: 50%;
        bottom: -38px;
        left: 2px;
      }
    }

    &--is-open {
      &:before {
        transform: rotate(180deg);
      }
    }

    @content;
  }
}

@mixin styled-scrollbar(
  $width: rem-calc(14),
  $height: rem-calc(14),
  $border-radius: rem-calc(8),
  $track-background: transparent,
  $thumb-background: get($colors, 'main'),
  $thumb-border-color: transparent,
  $thumb-border-width: rem-calc(3),
  $track-margin: 0
) {
  //Not yet supported by W3C Validation
  // scrollbar-color: $thumb-background $track-background;
  // scrollbar-wig  dth: auto;

  &::-webkit-scrollbar {
    width: $width;
    height: $height;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $border-radius;
    border: $thumb-border-width solid $thumb-border-color;
    background: $thumb-background;
    background-clip: content-box;
  }

  &::-webkit-scrollbar-track {
    margin: $track-margin;
    border-radius: $border-radius;
    background-color: $track-background;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
}

@mixin ActiveBar(
  $background: linear-gradient(147.57deg, c('nav') 30.89%, #9700cc)
) {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  width: 6px;
  background: $background;
  height: 100%;
  z-index: 1;
  left: -20px;
}

@mixin link-arrow(
  $color: $white,
  $bgColor: $black-70,
  $hoverColor: $miami,
  $hoverBgColor: $white,
  $top: 13px,
  $left: 8px,
  $fontSize: rem-calc(20),
  $bgSize: rem-calc(18),
  $bgTop: $top + 1px,
  $bgLeft: $left + 1px,
  $paddingLeft: 25px,
  $rotate: 0deg,
) {
  padding-left: $paddingLeft;
  position: relative;

  [dir='rtl'] & {
    padding-left: 0;
    padding-right: $paddingLeft;
  }

  @include icon('e808');

  &:before {
    color: $color;
    left: $left;
    font-size: $fontSize;
    position: absolute;
    top: $top;
    z-index: 2;
    transform: rotate($rotate);

    [dir='rtl'] & {
      left: auto;
      right: 8px;
      transform: scaleX(-1);
    }
  }
  &:after {
    background: $bgColor;
    border-radius: 100%;
    content: '';
    display: block;
    height: $bgSize;
    left: $bgLeft;
    opacity: 1;
    position: absolute;
    top: $bgTop;
    width: $bgSize;
    z-index: 1;

    [dir='rtl'] & {
      left: auto;
      right: 0;
    }
  }

  &:hover,
  &:focus,
  &:active {
    &:before {
        background: $hoverColor;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    &:after {
      background: $hoverBgColor;
    }
  }
  @content;
}