.c-contact-place-card {
  &__container {
    @include subheading-medium-font-family();
    position: relative;
    display: flex;
    margin-top: rem-calc(20);
    padding-bottom: rem-calc(24);
    padding-top: rem-calc(20);
    flex-direction: column;
    border-radius: rem-calc(9);

    &--article-page {
      &:after {
        position: absolute;
        content: '';
        height: 1px;
        background: $black;
        width: 100vw;
        left: -20px;
        top: -1px;

        @include breakpoint(medium) {
          height: 0px;
        }
      }
    }

    @include breakpoint(medium) {
      box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.2);
      flex-direction: row;
      padding-inline: rem-calc(20);
    }

    &__desktop-logo {
      display: none;

      @include breakpoint(medium) {
        display: flex;
        margin-right: rem-calc(24);
      }

      &__img-frame {
        display: flex;
        border-radius: 100%;
        box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3) inset;
        width: rem-calc(60);
        height: rem-calc(60);
        max-width: rem-calc(60);

        @include breakpoint(medium) {
          width: rem-calc(125);
          height: rem-calc(125);
          max-width: rem-calc(125);
        }

        img {
          z-index: -1;
          object-fit: cover;
        }
      }
    }

    &__description {
      display: flex;
      flex: 1;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      word-break: break-word;

      &__header {
        @include subheading-medium-font-family();
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-grow: 1;
        gap: rem-calc(16);

        h3 {
          @include subheading-medium-font-family();
          color: $text-black;
          font-size: rem-calc(18);
          font-style: normal;
          font-weight: 700;
          line-height: rem-calc(28);
        }

        &__mobile-logo {
          @include breakpoint(medium) {
            display: none;
            gap: 0;
          }

          &__img-frame {
            display: flex;
            width: rem-calc(60);
            height: rem-calc(60);
            max-width: rem-calc(60);
            border-radius: 100%;
            box-shadow: 2px 2px 10px 0px rgba(0, 0, 0, 0.3) inset;
            margin-right: 16px;

            img {
              z-index: -1;
            }
          }
        }
      }

      &__text {
        @include subheading-medium-font-family();
        color: $text-black;
        flex-grow: 1;
        margin: 0;
        margin-top: rem-calc(8);
      }

      &__footer {
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        flex-direction: column;

        a {
          text-underline-offset: 3px;
        }

        @include breakpoint(medium) {
          flex-direction: row;
        }

        flex-grow: 1;
        margin-top: rem-calc(16);
        gap: rem-calc(16);

        &__cta-container {
          &__cta-primary-button {
            @include subheading-medium-font-family();
            @include LinkButton(
              $bgColor: $black-70,
              $hoverBgColor: c('purple'),
              $border: 2px solid $black-70,
              $color: $white,
              $hoverColor: $white,
              $fontSize: rem-calc(18)
            );
          }

          &__cta-secondary-button {
            @include subheading-medium-font-family();
            @include LinkButton(
              $bgColor: $white,
              $hoverBgColor: c('purple'),
              $border: 2px solid $black-70,
              $color: c('grey-700'),
              $hoverColor: $white,
              $fontSize: rem-calc(18)
            );
          }
        }

        &__link-container {
          display: flex;
          flex-direction: row;
          align-items: center;

          &__globe-icon {
            width: rem-calc(18);
            height: rem-calc(19);
            background-image: url('../../framework/images/umo/globe-icon.svg');
            background-repeat: no-repeat;
            background-position: center;
            margin-right: rem-calc(12);
          }

          &__email-icon {
            width: 17px;
            height: 14px;
            background-image: url('../../framework/images/umo/email-icon.svg');
            background-repeat: no-repeat;
            background-position: center;
            margin-right: rem-calc(12);
          }

          &__chat-bubble-icon {
            width: rem-calc(20);
            height: rem-calc(22);
            background-image: url('../../framework/images/umo/chat-bubble-icon.svg');
            background-repeat: no-repeat;
            background-position: center;
            margin-right: rem-calc(12);
          }

          &__phone-icon {
            width: rem-calc(12);
            height: rem-calc(21);
            background-image: url('../../framework/images/umo/phone-icon.svg');
            background-repeat: no-repeat;
            background-position: center;
            margin-right: rem-calc(16);

            @include breakpoint(medium) {
              margin-right: rem-calc(12);
            }
          }

          &__email {
            text-decoration: underline;
          }

          &__arrow-icon {
            background-image: url('../../framework/images/umo/arrow-icon.svg');
            background-repeat: no-repeat;
            background-position: center;
            width: rem-calc(16);
            height: rem-calc(16);
            margin-left: rem-calc(4);
            display: inline-block;
          }

          &__phone-number {
            a {
              @include subheading-medium-font-family();
              color: $text-black;
              text-decoration: underline;
              display: flex;
              align-items: center;
              flex-direction: row;
              font-size: rem-calc(16);
            }
          }

          &__a-link {
            a {
              @include subheading-medium-font-family();
              color: $text-black;
              text-decoration: underline;
              display: flex;
              align-items: center;
              flex-direction: row;
              font-size: rem-calc(16);
            }
          }
        }
      }
    }

    &__filters {
      margin-top: rem-calc(24);

      @include breakpoint(medium) {
        margin-top: 0;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: rem-calc(16);

        @include breakpoint(medium) {
          grid-template-columns: repeat(2, 1fr);
          padding-left: rem-calc(12);
          direction: rtl;
        }
      }

      li {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        img {
          height: 2rem;
          max-width: rem-calc(45);
        }

        @include breakpoint(medium) {
          margin-top: 0;
        }

        p {
          @include subheading-medium-font-family();
          margin-top: rem-calc(6);
          font-style: italic;
          text-transform: uppercase;
          color: c('grey-700');
          font-weight: 400;
          line-height: normal;
          letter-spacing: rem-calc(1);
          font-size: rem-calc(12);
          margin-bottom: 0;
          max-width: 4.6875rem;
        }
      }
    }

    &--first-item {
      &:after {
        position: absolute;
        content: '';
        height: rem-calc(1);
        background: $black;
        width: 100vw;
        left: -1.25rem;
        top: rem-calc(-18);

        @include breakpoint(medium) {
          display: none;
        }
      }
    }
  }

  &:after {
    position: absolute;
    content: '';
    height: rem-calc(1);
    background: $black;
    width: 100vw;
    left: 0px;

    @include breakpoint(medium) {
      height: 0px;
    }
  }
}
