@import '../../platformui/DescriptionList/styles';

@include DescriptionList($background: transparent, $numberBackground: $white, $blockH2Color: $red, $blockH3H4Color: $darker-grey) {
  margin: 0;
  padding: 0;
  list-style-type: none;

  h2 {
    @include base-font-family();
    font-size: rem-calc(22);
    line-height: rem-calc(30);
    letter-spacing: 0.25px;
    font-weight: 400;

    @include breakpoint(medium) {
      font-size: rem-calc(26);
      line-height: rem-calc(32);
    }
  }

  h3 {
    font-size: rem-calc(18);
    line-height: rem-calc(28);
    font-weight: 700;
  }

  &__preamble {
    font-size: rem-calc(16);
    font-style: normal;
    font-weight: 400;
    line-height: rem-calc(26);
    margin-bottom: 63px;
  }

  &__item {
    border: 0px;
    box-shadow: none;

    &__content {
      padding: 35px;

      .list-item-header-container {

        h3,
        h4 {
          position: relative;
          z-index: 1;

          font-size: rem-calc(18);
          font-style: normal;
          font-weight: 700;
          line-height: rem-calc(28);
        }

        span {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          background: $white;
          border-radius: 50%;
          font-size: rem-calc(40);
          width: 55.11px;
          height: 61px;
          z-index: 1;
          top: -30px;
          left: 20px;

          @include breakpoint(medium) {
            top: -34px;
          }

          p {
            position: absolute;
            margin: 0;
            color: $umo-darker-grey;
            font-style: italic;
            text-align: center;
            font-size: rem-calc(44);
            font-style: normal;
            font-weight: 700;
            line-height: rem-calc(26);
          }
        }
      }

      a {
        color: c('grey-800');
        text-decoration: underline !important;
      }

      p {
        z-index: 1;
        color: $text-black;
        font-size: rem-calc(16);
        font-style: normal;
        font-weight: 400;
        line-height: rem-calc(26);
      }

      &:after {
        z-index: -1;
        position: absolute;
        content: '';
        height: 100%;
        background: $description-list-gradient;
        width: 100vw;
        top: 0px;
        left: -20px;

        @include breakpoint(medium) {
          width: 100%;
          left: 0px;
          border-radius: 10px;
        }
      }
    }
  }
}
