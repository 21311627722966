@import '../../platformui/SurveyEntry/styles.scss';

@include SurveyEntry(
  $iconColor: c('white'),
  $iconBackgroundColor: c('black-70'),
  $linkColor: c('white'),
  $linkHoverColor: c('white'),
  $linkBackgroundColor: c('black-70'),
  $linkHoverBackground: rgb(123, 123, 128),
  $externalLinkIcon: 'E808',
  $backgroundColor: c('red-5'),
  $border: 3px solid c('white'),
  $borderRadius: 20px
){
  &__link {
    @include base-font-family();
    text-transform: none;

    &:after {
      transform: rotate(-45deg);
    }
  }
};