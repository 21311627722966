.c-focus-container-block {
  display: grid;
  max-width: rem-calc(1048);
  margin: rem-calc(35) auto;
  gap: rem-calc(15);
  padding-inline: rem-calc(15);

  @include breakpoint(large) {
    grid-template-columns: repeat(3, 1fr);
    gap: rem-calc(30);
    padding-inline: 0;
  }
}

.c-focus-block {
  display: flex;
  padding: rem-calc(20);
  overflow: hidden;
  position: relative;

  h2,
  p {
    @include base-font-family();
  }

  h2 {
    color: c('black');
    font-size: rem-calc(20);
  }

  p {
    font-size: rem-calc(16);
    @include breakpoint(large) {
      max-width: rem-calc(480);
      font-size: rem-calc(22);
    }
  }

  &__inner {
    width: 100%;
  }

  &.full-width {
    width: 100%;
    margin-block: rem-calc(35);
    padding: rem-calc(40) rem-calc(30);

    @include breakpoint(large) {
      padding: rem-calc(40);
    }

    h2 {
      @include breakpoint(medium) {
        font-size: rem-calc(26);
      }
    }

    .c-focus-block__inner {
      @include breakpoint(medium) {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        max-width: rem-calc(1048);
        margin: 0 auto;
      }
    }

    .c-focus-block__icon {
      @include breakpoint(large) {
        position: relative;
        width: rem-calc(280);
        height: rem-calc(340);
        background-size: rem-calc(280) auto;
      }
    }
  }

  &__icon {
    display: block;
    position: absolute;
    top: rem-calc(-10);
    right: rem-calc(10);
    width: rem-calc(50);
    height: rem-calc(60);
    background-size: rem-calc(50) auto;
    background-repeat: no-repeat;
    background-position: center;
  }

  &__informationType {
    text-transform: uppercase;
    @include secondary-font-family();
    font-style: italic;
    font-size: rem-calc(12);
    display: flex;
    gap: rem-calc(10);
    align-items: center;
    margin-bottom: rem-calc(15);

    .icon {
      display: none;
      width: rem-calc(21);
      height: rem-calc(25);
      background-size: rem-calc(21) auto;
      background-repeat: no-repeat;
      background-position: center;
      @include breakpoint(large) {
        display: block;
      }
    }

    span {
      background-color: c('white');
      padding-inline: rem-calc(5);
      display: block;
    }
  }

  &__link {
    display: flex;
    &::before {
      margin-right: 5px;
      content: url('../../framework/images/umo/link.svg');
    }
  }

  &__button {
    @include LinkButton(
      $bgColor: $white,
      $hoverBgColor: c('purple'),
      $border: 2px solid $black-70,
      $color: c('grey-700'),
      $hoverColor: $white
    );
    @include breakpoint(medium) {
      max-width: fit-content !important;
    }
    @include focus-handler($color: #484848, $offset: 4px);
  }

  &.theme {
    &--article,
    &--facts,
    &--other {
      background: c('rainbow');
      .c-focus-block__icon,
      .c-focus-block__informationType .icon {
        background-image: url('../../framework/images/umo/article-bubble-small.svg');
      }
    }
    &--story {
      background: c('theme-story');
      .c-focus-block__icon,
      .c-focus-block__informationType .icon {
        background-image: url('../../framework/images/umo/story-bubble-small.svg');
      }
    }
    &--faq {
      background: c('theme-faq');
      .c-focus-block__icon,
      .c-focus-block__informationType .icon {
        background-image: url('../../framework/images/umo/faq-bubble-small.svg');
      }
    }
    &--shortstory {
      background: c('theme-shortstory');
      .c-focus-block__icon,
      .c-focus-block__informationType .icon {
        background-image: url('../../framework/images/umo/shortstory-bubble-small.svg');
      }
    }
    &--support {
      background: c('theme-support');
      .c-focus-block__icon,
      .c-focus-block__informationType .icon {
        background-image: url('../../framework/images/umo/support-bubble-small.svg');
      }
    }
    &--forum {
      background: c('theme-forum');
      .c-focus-block__icon,
      .c-focus-block__informationType .icon {
        background-image: url('../../framework/images/umo/forum-bubble-small.svg');
      }
    }
    &--tip {
      background: c('theme-tip');
      .c-focus-block__icon,
      .c-focus-block__informationType .icon {
        background-image: url('../../framework/images/umo/tip-bubble-small.svg');
      }
    }
    &--podcast {
      background: c('theme-podcast');
      .c-focus-block__icon,
      .c-focus-block__informationType .icon {
        background-image: url('../../framework/images/umo/podcast-bubble-small.svg');
      }
    }
  }
}
