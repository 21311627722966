@import '../../platformui/Modal/tooltip-modal.scss';

@include TooltipModal(
  $className: 'c-letter-slide',
  $pseudoIcon: before,
  $buttonFloat: right,
  $containerWidth: (
    calc(100% - 20px),
    calc(50% - 20px),
  ),
  $modalPosition: (
    5px,
    10px,
    40px,
  ),
  $modalCloseBtnColor: c('purple'),
  $modalBtnColorOpen: c('purple'),
  $scrollbarColor: c('white'),
  $conditions: c('purple'),
  $focusColor: c('purple'),
  $scrollbarBg: c('purple')
) {
  .c-letter-slide__modal {
    position: relative;

    &-panel {
      left: 0;
      right: 10px;
      height: auto;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
      top: 1.875rem;
      transform: translateY(0);

      &--position-top {
        top: -1rem;
        transform: translateY(-100%);
      }

      &__content {
        max-height: 11.5625rem;
        height: auto;

        h1,
        h2,
        h3,
        h4 {
          margin-top: 0px;
          margin-bottom: 6px;
          font-family: $secondary-font;
          font-size: rem-calc(16);
          font-weight: 700;
        }
      }

      &__description {
        padding-bottom: 0;

        p {
          word-break: break-word;
        }

        p:last-of-type {
          margin-bottom: 10px;
        }
      }
    }

    &__container {
      position: relative;
    }

    &-open {
      display: flex;
      float: none;
      border-radius: rem-calc(5);
      margin-block: rem-calc(10);

      p {
        margin-bottom: 0;
      }

      @include focus-handler();

      &:after {
        display: none;
      }

      span {
        &:before {
          content: '';
          width: 20px;
          height: 20px;
          background-image: url('../../framework/icons/svg/info.svg');
          background-repeat: no-repeat;
          background-position: right bottom;
        }
      }

      &:before {
        z-index: 20;
      }
    }

    &-close {
      width: 25px;
      height: 25px;
      @include focus-handler();
      cursor: pointer;

      &:before,
      &:after {
        content: '';
        position: absolute;
        height: 3px;
        width: 25px;
        top: 15px;
        left: -3px;
        margin-top: -1px;
        background: c('purple');
      }

      &:before {
        transform: rotate(45deg);
      }

      &:after {
        transform: rotate(-45deg);
      }
    }
  }
}
