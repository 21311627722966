@mixin SurveyEntry(
  $icon: 'E836',
  $iconColor: black,
  $iconBackgroundColor: inherit,
  $headingColor: black,
  $linkColor: inherit,
  $linkHoverColor: inherit,
  $linkBackgroundColor: black,
  $linkHoverBackground: black,
  $linkPadding: (
    10px 15px,
    15px 30px,
  ),
  $linkFocusOutlineColor: black,
  $externalLinkIcon: 'E809',
  $backgroundColor: white,
  $border: 3px solid white,
  $borderRadius: 20px
) {
  .surveyentry {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 20px 15px;
    background-color: $backgroundColor;
    border: $border;
    border-radius: $borderRadius;
    margin: 30px 15px;
    overflow: visible;
    box-shadow: 0px 0px 10px 0px rgba(#000, 0.3);
    row-gap: 10px;

    @include breakpoint(medium) {
      row-gap: 0;
    }

    &__icon {
      @include icon($icon);
      &:before {
        font-size: 30px;
        color: $iconColor;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: $iconBackgroundColor;
        border-radius: 100px;
        top: 0;
        left: 0;
        min-height: 50px;
        min-width: 50px;
        color: $iconColor;
        margin-right: 10px;
      }
    }

    &__container {
      display: flex;
      flex-direction: column;
      row-gap: 10px;

      @include breakpoint(medium) {
        margin-left: 60px;
      }

      @include breakpoint(large) {
        display: grid;
        grid-template-columns: 600px 1fr;
        column-gap: 20px;
      }

      &--no-icon {
        margin-left: 0;
      }
    }

    &__content__container {
      word-wrap: break-word;
      @include breakpoint(medium) {
        display: block;
      }

      p:last-of-type {
        margin-bottom: 0;
      }
    }

    &__heading {
      display: inline-block;
      color: $headingColor;
      margin: 8px 0 0;

      &__container {
        display: flex;

        @include breakpoint(large) {
          max-width: 600px;
        }
      }
    }

    &__link {
      color: $linkColor;
      background-color: $linkBackgroundColor;
      padding: nth($linkPadding, 1);
      border-radius: 25px;
      text-decoration: none;
      text-align: center;
      text-transform: uppercase;
      font-size: 1.125rem;
      line-height: normal;

      @include icon($externalLinkIcon, $pseudo: after);

      &:after {
        margin-left: 10px;
      }

      // Check if the link is internal and then remove the external logotype
      &[href^='/'] {
        &:after {
          content: none;
        }
      }

      @include breakpoint(medium) {
        width: fit-content;
        height: fit-content;
        padding: nth($linkPadding, 2);
      }

      @include breakpoint(large) {
        position: absolute;
        bottom: calc(50% - 25px);
        right: 25px;
      }

      &:hover {
        color: $linkHoverColor;
        background-color: $linkHoverBackground;
        text-decoration: none;
      }

      @include focus-handler($linkFocusOutlineColor, $offset: 4px);
    }

    @media print {
      display: none !important;
    }

    @content;
  }
}
