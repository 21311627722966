.language-selector {
  position: relative;
  width: 320px;
  height: 530px;
  margin: 0 auto;

  @include breakpoint(medium) {
    width: 500px;
    height: 645px;
  }

  @include breakpoint(large) {
    width: 640px;
    height: 790px;
  }

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 320px;
    background: url('../../framework/images/youmo/language-persons.svg') no-repeat;
    background-size: 100%;
    filter: drop-shadow(0 0 14px rgba(0, 0, 0, 0.7));
    right: 5px;
    transform: translateY(56px);

    @include breakpoint(medium) {
      transform: translateY(50px);
    }

    @include breakpoint(large) {
      transform: translateY(30px);
      height: 385px;
    }
  }

  &:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 640px;
    margin-top: 20px;
    background: url('../../framework/images/youmo/language-bubble-mobile.svg') no-repeat;
    background-size: 100%;
    filter: drop-shadow(0 0 14px rgba(0, 0, 0, 0.7));

    @include breakpoint(medium) {
      height: 514px;
      background: url('../../framework/images/youmo/language-bubble.svg') no-repeat;
      background-size: 100%;
    }
  }

  h1 {
    @include subheading-medium-font-family();
    text-align: center;
    font-size: rem-calc(30);
    margin: 23px 0 15px;
    font-weight: 400;

    @include breakpoint(medium) {
      margin-bottom: 26px;
    }

    @include breakpoint(large) {
      font-size: rem-calc(40);
      margin: 40px 0 26px;
    }
  }

  .c-articleheader {
    padding: 23px 25px 0;

    @include breakpoint(medium) {
      padding: 55px 55px 0;
    }
  }
}
