@import '../../platformui/FindCare/findcare-search-list-item/findcare-search-list-item.scss';

@include FindCare-Searchlistitem() {
  .findcare-search-list-item {
    padding-left: 8px;
    padding-top: 8px;
    &--focus {
      background-color: rgba($color: $red, $alpha: 0.2);
      outline: 2px solid c('grey-600');
      outline-offset: 2px;
    }
    background-color: rgba($color: $red, $alpha: 0.1);

    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath fill='%23f01e00' d='M40 0v40H0C0 18.13 17.552.359 39.339.005L40 0z'/%3E%3Cpath fill='%23FFF' d='M31.417 23.412l-6.655 6.845c-.56.61-1.263 1.145-2.187 1.143-1.487-.003-2.58-1.076-2.575-2.423 0-.715.616-1.404 1.153-1.885l3.717-3.68-3.874-3.684c-.59-.586-.981-1.17-.98-2.013.003-1.323 1.122-2.315 2.554-2.315 1.011.003 1.71.616 2.3 1.253l6.547 6.76z'/%3E%3C/g%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: right bottom;

    &__info a:hover {
      text-decoration: underline;
    }
    &__info a:after {
      content: '';
      position: absolute;
      top: auto;
      bottom: 0;
      right: 0;
      left: auto;
      width: 60px;
      height: 60px;

      @include breakpoint(medium) {
        top: 0;
        height: auto;
        width: 200px;
      }
    }
    .c-related__item {
      transition: none;
      transform: none;
      z-index: auto;
      margin-bottom: 3px;

      &:before {
        display: none;
        border-left: none;
        margin-left: 50px;
      }
      &:after {
        display: none;
      }
      @include breakpoint(medium) {
        padding-left: 42px;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='42' height='42' viewBox='0 0 42 42'%3E%3Cdefs%3E%3Cpath id='prefix__a' d='M12.285 0.246L0 0.246 0 23.958 12.285 23.958 12.285 0.246z'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Ccircle cx='21' cy='21' r='21' fill='%23FFF'/%3E%3Cg transform='translate(9 7)'%3E%3Cmask id='prefix__b' fill='%23fff'%3E%3Cuse xlink:href='%23prefix__a'/%3E%3C/mask%3E%3Cpath fill='%23F01E00' d='M10.09 8.565c0 .459-.395.83-.875.83H7.46c-.485 0-.881-.371-.881-.83V6.902c0-.459.396-.832.881-.832h1.755c.48 0 .876.373.876.832v1.663zm0 4.58c0 .457-.395.828-.875.828H7.46c-.485 0-.881-.37-.881-.829v-1.669c0-.457.396-.831.881-.831h1.755c.48 0 .876.374.876.831v1.67zm0 4.572c0 .459-.395.832-.875.832H7.46c-.485 0-.881-.373-.881-.832v-1.664c0-.46.396-.832.881-.832h1.755c.48 0 .876.373.876.832v1.664zM11.407.246L.876 6.07c-.482 0-.876.373-.876.832v16.223c0 .461.394.833.876.833h10.53c.485 0 .879-.372.879-.833V1.078c0-.46-.394-.832-.879-.832z' mask='url(%23prefix__b)' opacity='.5'/%3E%3Cpath fill='%23F01E00' d='M20.342 13.05c0 .468-.364.845-.812.845h-1.624c-.45 0-.81-.377-.81-.845v-1.68c0-.464.36-.842.81-.842h1.624c.448 0 .812.378.812.841v1.681zm0 4.633c0 .466-.364.845-.812.845h-1.624c-.45 0-.81-.38-.81-.845v-1.684c0-.465.36-.84.81-.84h1.624c.448 0 .812.375.812.84v1.684zm-4.467-4.633c0 .468-.364.845-.814.845h-1.625c-.449 0-.812-.377-.812-.845v-1.68c0-.464.363-.842.812-.842h1.625c.45 0 .814.378.814.841v1.681zm0 4.633c0 .466-.364.845-.814.845h-1.625c-.449 0-.812-.38-.812-.845v-1.684c0-.465.363-.84.812-.84h1.625c.45 0 .814.375.814.84v1.684zM21.967 8H13.03C11.908 8 11 8.944 11 10.107v11.785c0 1.164.908 2.108 2.03 2.108h8.937C23.09 24 24 23.056 24 21.892V10.107C24 8.944 23.091 8 21.967 8z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        z-index: auto;
      }
    }

    .c-related__item__container {
      padding: 16px 20px 20px 20px;
      @include breakpoint(medium) {
        padding: 5px 20px 0px 20px;
      }
      &--no-z-index {
        z-index: auto;
      }
      .findcare-search-list-item {
        &__info a h3 {
          @include subheading-font-family();
          font-size: 18px;
          color: c('grey-600');
        }

        &__address {
          font-size: 14px;
          color: c('text');
        }

        &__contact-list * {
          font-size: 15px;
          color: c('text');
        }
        &__contact-list__phone {
          padding-left: 20px;
          text-decoration: underline;
          &:before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='18' viewBox='0 0 9 18'%3E%3Cg fill='%23F01E00' fill-rule='evenodd'%3E%3Cpath d='M5.438 13.5c0-.516-.422-.938-.938-.938s-.938.422-.938.938.422.938.938.938.938-.422.938-.938zm2.437-1.875v-8.25c0-.2-.176-.375-.375-.375h-6c-.2 0-.375.176-.375.375v8.25c0 .2.176.375.375.375h6c.2 0 .375-.176.375-.375zm-2.25-9.938c0-.105-.082-.187-.188-.187H3.563c-.105 0-.187.082-.187.188 0 .105.082.187.188.187h1.874c.106 0 .188-.082.188-.188zM9 1.5v12c0 .82-.68 1.5-1.5 1.5h-6C.68 15 0 14.32 0 13.5v-12C0 .68.68 0 1.5 0h6C8.32 0 9 .68 9 1.5z'/%3E%3Cellipse cx='4.5' cy='17' fill-opacity='.2' rx='4.5' ry='1'/%3E%3C/g%3E%3C/svg%3E%0A");
            background-size: 18px 16px;
            background-repeat: no-repeat;
            background-size: 100% 100%;
            height: 18px;
            width: 10px;
            left: 0;
            top: 0;
          }
        }
        &__list {
          border-top: none;
          margin: 0;
          font-size: 15px;
          color: c('text');
        }
        &__eservices {
          p {
            @include icon('E81D');
            &:before {
              font-size: 16px;
              color: $red;
              margin-right: 4px;
              top: 2px;
            }
          }
        }
      }
    }
  }
}
