$default-size: 1.875rem;

.c-trackbar {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .625rem;
  position: relative;

  &__input-progress--track-bg {
    position: absolute;
    z-index: 0;
    background-color: #fff;
    height: 10px;
    width: 100%;
    border-radius: 5px;
    top: 1px;
    left: 1px;
    height: .5rem
  }

  &__input-progress {
    position: absolute;
    height: .5rem;
    width: 0;
    max-width: 99%;
    left: 1px;
    top: 1px;
    z-index: 0;
    background: $miami-rev-50;
    border-radius: 5px;
  }

  &__input {
    appearance: none;
    width: 100%;
    height: 10px;
    background: transparent;
    border-radius: 5px;
    border: 1px solid #333;
    outline: none;
    position: relative;
    @include focus-handler();

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: $default-size;
      height: $default-size;
      background: $miami-rev-50;
      border: 5px solid #333;
      outline: 3px solid #fff;
      outline-offset: -4px;
      border-radius: 50%;
      position: relative;
      z-index: 9;
      cursor: pointer;

      @media (max-width: 320px) {
        width: 1.625rem;
        height: 1.625rem;
      }
    }

    &::-moz-range-thumb {
      width: $default-size -10;
      height: $default-size -10;
      background: $miami-rev-50;
      border: 5px solid #333;
      outline: 3px solid #fff;
      outline-offset: -4px;
      border-radius: 50%;
      position: relative;
      z-index: 9;
      cursor: pointer;

      @media (max-width: 320px) {
        width: 1.625rem -10;
        height: 1.625rem -10;
      }
    }
  }

  &__scale-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: rem-calc(7px);

    @media (max-width: 320px) {
      justify-content: space-evenly;
    }

    @include breakpoint(medium) {
      justify-content: space-between;
    }

    @include breakpoint(large) {
      justify-content: space-between;
    }
  }

  &__scale-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: $default-size;
    font-size: .875rem;
    gap: .3125rem;
    @include secondary-font-family();

    @media (max-width: 320px) {
      width: 1.625rem;
    }

    &:before {
      display: block;
      background-color: #808080;
      width: 1px;
      height: 10px;
      content: '';
      margin: 0 auto;
    }
  }
}
