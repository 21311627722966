@import '../../platformui/AnchorNavHorizontal/styles';

@include AnchorNavHorizontal(
  $backgroundColor: $shimmer,
  $fontFamily: $base-font,
  $mobileHeadingFont: $secondary-font,
  $mobileExpandButtonBorderBg: $miami,
  $mobileExpandButtonIcon: 'e804',
  $mobileExpandButtonIconColor: #fff,
  $mobileExpandButtonIconBg: #4d4d4d,
  $mobileExpandButtonExpandedMenuBg: #fff,
  $fontWeight: 400,
  $iconRotate: rotate(180deg),
  $transformOrigin: center center,
  $activeLinkIconBg: $miami,
  $activeLinkIconColor: $white,
  $linkArrowColor: $black-70,
  $linkArrowBg: $white,
  $borderColor: #3b4266,
  $borderWidth: 0.3px,
  $smallerScreensBorderWidth: 0px
) {
}
