@keyframes bounceIn {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 1;
    transform: scale3d(0.8, 0.8, 0.8);
  }

  20% {
    transform: scale3d(1.4, 1.4, 1.4);
  }

  40% {
    transform: scale3d(0.9, 0.9, 0.9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.2, 1.2, 1.2);
  }

  80% {
    transform: scale3d(0.97, 0.97, 0.97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes bounceInSmall {
  from,
  20%,
  40%,
  60%,
  80%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  0% {
    opacity: 1;
    transform: scale3d(0, 0, 0);
  }

  30% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(0.99, 0.99, 0.99);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.05, 1.05, 1.05);
  }

  80% {
    transform: scale3d(0.98, 0.98, 0.98);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 5.5deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 0.5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -0.5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
/*
@keyframes blind {
  20% {
    transform: translateY(-10px);
  }

  40% {
    transform: translateY(0);
  }

  80% {
    transform: translateY(10px);
  }

  to {
    transform: translateY(0);
  }
}*/

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes imgLoading {
  0% {
    opacity: 1;
  }
  10% {
    opacity: 0.8;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
