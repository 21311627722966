fieldset {
  border: none;
  margin: 0;
  padding: 0;
}

button {
  padding: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  line-height: 1;
  cursor: auto;
  @include focus-handler();

  @media print {
    display: none !important;
  }
}

@include site-theme(youmo) {
  a {
    color: $youmo-link;
  }
}
