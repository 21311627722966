@import '../../platformui/AnchorNav/styles';

@include AnchorNav() {
  .anchor-nav {
    &__navigation {
      border-top: none;
      padding: rem-calc(25);
    }
    &__heading {
      color: c('grey-700');
    }
    &__list {
      li {
        margin: 0;
        padding: 0;
      }
      &__link {
        color: c('grey-700');
        text-decoration: none;
        padding-block: rem-calc(13);
        padding-left: rem-calc(40);
        position: relative;

        &--is-selected {
          font-weight: bold;
          background-color: $black-05;
          &:before {
            background: $miami;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          &:after {
            background: $white;
          }
        }
      }
    }
  }
}
