//Modified Foundation import.

/**
 * Foundation for Sites by ZURB
 * Version 6.3.0
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */

@import './foundation-settings.scss';
@import 'foundation-sites/scss/foundation.scss';

@include foundation-global-styles;
@include foundation-grid;
//@include foundation-typography
//@include foundation-flex-grid;
//@include foundation-typography;
//@include foundation-button;
//@include foundation-forms;
//@include foundation-range-input;
//@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
//@include foundation-close-button;
//@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
//@include foundation-flex-video;
//@include foundation-label;
//@include foundation-media-object;
//@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-progress-element;
//@include foundation-meter-element;
//@include foundation-slider;
//@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;
@include foundation-visibility-classes;
//@include foundation-float-classes;
// @include foundation-flex-classes;

//Fix Foundation for rtl
.columns {
  [dir='rtl'] & {
    float: right;
  }
}

.column:last-child:not(:first-child),
.columns:last-child:not(:first-child) {
  [dir='rtl'] & {
    float: left;
  }
}

.row {
  margin-right: rem-calc(13);
  margin-left: rem-calc(13);

  @include breakpoint(large) {
    margin-right: auto;
    margin-left: auto;
  }
}

@include breakpoint(medium) {
  .medium-offset-2 {
    [dir='rtl'] & {
      margin-left: 0;
      margin-right: 16.66667%;
    }
  }
}
@include breakpoint(large) {
  .large-push-0 {
    [dir='rtl'] & {
      margin-right: 0;
      margin-left: 0;
    }
  }

  .large-push-1 {
    [dir='rtl'] & {
      left: auto;
      right: 8.33333%;
    }
  }

  .large-offset-0 {
    [dir='rtl'] & {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .large-offset-1 {
    [dir='rtl'] & {
      margin-left: 0;
      margin-right: 8.33333%;
    }
  }
}

.large-uncentered:last-child:not(:first-child) {
  @include breakpoint(large) {
    position: static;
    float: right;
    margin-right: 0;
    margin-left: 0;
    clear: none;
  }
}
