@import '../../platformui/SearchArticleList/styles';

@mixin articleListIcon($content: '') {
  &::before {
    content: $content;
    position: absolute;
    right: 15px;
    margin-top: -15px;
  }
}

@mixin vignette($background: white, $margin: 0 0 10px 0) {
  margin: $margin;
  display: block;
  width: fit-content;
  padding: 3px 5px;
  background: $background;

  @include secondary-font-family();
  font-style: italic;
  font-size: 12px;

  letter-spacing: 0.25px;
  text-transform: uppercase;
  color: c('grey-900');
}

@mixin searchListIcon($content: '') {
  &::before {
    content: $content;
    background: none;
  }
}

@include SearchArticleList() {
  .c-search-main-content .link-item-list .search-list__item:last-of-type {
    border-bottom: 1px solid c('grey-500');
  }
  .c-search-list {
    &__heading {
      text-align: center;
      @include breakpoint(medium) {
        text-align: left;
      }
    }

    .c-image {
      float: right;
      max-width: 80px;
      margin-top: 40px;
      margin-right: 0;

      @include breakpoint(medium) {
        max-width: 200px;
        margin: 0;
      }
    }

    //SÖKDRIVEN ARTIKELLISTA I INNEHÅLLSYTAN PÅ ARTIKELSIDA
    .search-list {
      padding: 0;
      &__content {
        overflow: unset;
        max-height: none;
        &::before {
          content: none;
        }
      }
      &__title {
        @include base-font-family();
        font-weight: normal;
        font-size: 1.25rem;
        line-height: 26px;
        :hover {
          color: c('grey-900');
        }
        a {
          &::before {
            margin-right: 5px;
            content: url('../../framework/images/umo/link.svg');
          }
        }
      }
      &__text {
        @include secondary-font-family();
        font-size: 1rem;
        line-height: 24px;
      }
      &__type {
        &--podcast {
          .search-list {
            &__vignette {
              @include vignette(
                $margin: 0 0 15px 30px,
                $background: c('theme-podcast')
              );
            }
            &__content {
              @include searchListIcon(
                $content:
                  url('../../framework/images/umo/podcast-bubble-small.svg')
              );
            }
          }
        }
        &--story {
          .search-list {
            &__vignette {
              @include vignette(
                $margin: 0 0 15px 30px,
                $background: c('theme-story')
              );
            }
            &__content {
              @include searchListIcon(
                $content:
                  url('../../framework/images/umo/story-bubble-small.svg')
              );
            }
          }
        }
        &--article, &--facts, &--other {
          .search-list {
            &__vignette {
              @include vignette(
                $margin: 0 0 15px 30px,
                $background: c('theme-article')
              );
            }
            &__content {
              @include searchListIcon(
                $content:
                  url('../../framework/images/umo/article-bubble-small.svg')
              );
            }
          }
        }
        &--faq {
          .search-list {
            &__vignette {
              @include vignette(
                $margin: 0 0 15px 30px,
                $background: c('theme-faq')
              );
            }
            &__content {
              @include searchListIcon(
                $content: url('../../framework/images/umo/faq-bubble-small.svg')
              );
            }
          }
        }
        &--forum {
          .search-list {
            &__vignette {
              @include vignette(
                $margin: 0 0 15px 30px,
                $background: c('theme-forum')
              );
            }
            &__content {
              @include searchListIcon(
                $content:
                  url('../../framework/images/umo/forum-bubble-small.svg')
              );
            }
          }
        }
        &--shortstory {
          .search-list {
            &__vignette {
              @include vignette(
                $margin: 0 0 15px 30px,
                $background: c('theme-shortstory')
              );
            }
            &__content {
              @include searchListIcon(
                $content:
                  url('../../framework/images/umo/shortstory-bubble-small.svg')
              );
            }
          }
        }
        &--office {
          .search-list {
            &__vignette {
              @include vignette(
                $margin: 0 0 15px 30px,
                $background: c('theme-office')
              );
            }
            &__content {
              @include searchListIcon(
                $content:
                  url('../../framework/images/umo/office-bubble-small.svg')
              );
            }
          }
        }

        &--support {
          .search-list {
            &__vignette {
              @include vignette(
                $margin: 0 0 15px 30px,
                $background: c('theme-support')
              );
            }
            &__content {
              @include searchListIcon(
                $content:
                  url('../../framework/images/umo/support-bubble-small.svg')
              );
            }
          }
        }
        &--tip {
          .search-list {
            &__vignette {
              @include vignette(
                $margin: 0 0 15px 30px,
                $background: c('theme-tip')
              );
            }
            &__content {
              @include searchListIcon(
                $content: url('../../framework/images/umo/tip-bubble-small.svg')
              );
            }
          }
        }
      }
      ul {
        padding: 0;
      }
      &__item {
        border-top: 1px solid c('grey-500');
        padding: 20px 0;
        margin-bottom: 0;
        p {
          max-height: none;
        }

        @include breakpoint(small only) {
          &--has-img {
            .search-list__content {
              overflow: unset;
            }
          }
        }
      }

      .c-article-list {
        &:hover,
        &:focus {
          &::after {
            content: none;
          }
        }
      }
    }
    .link-item-list__button {
      background: c('grey-700');
      border-radius: 30px;
      padding: 10px;
      text-align: center;
      margin-bottom: 25px;
      width: fit-content;
      min-width: 140px;
      max-width: none;
      span {
        @include base-font-family();
        font-weight: 400;
        color: white;
        font-size: 1.25rem;
      }
    }

    //SÖKDRIVEN ARTIKELLISTA PÅ STARTSIDA, SAMLINGSSIDA & PUFFYTAN PÅ EN ARTIKELSIDA

    .c-article-list {
      @include breakpoint(medium) {
        margin-top: 5px;
      }
      border-bottom: none;
      width: 100%;
      box-shadow: 0 2px 6px 0 rgb(30 30 30 / 20%);
      margin-bottom: 20px;
      background: c('theme-article');
      &:hover {
        transform: translateY(-3px);
      }

      &__content-wrapper {
        padding: 15px;
      }
      &__img-container {
        margin-bottom: 0px;
        border-radius: 3px 3px 0px 0px;
        .c-image {
          max-width: none;
          max-height: none;
          margin: 0;
        }
      }
      &__vignette {
        @include vignette();
      }
      &__heading {
        margin-top: 15px;
        @include base-font-family();
        font-size: 1.25rem;
        color: c('grey-900');
        span {
          &::before {
            margin-right: 5px;
            content: url('../../framework/images/umo/link.svg');
          }
        }
      }
      &__text {
        @include secondary-font-family();
        margin-top: 10px;
        font-size: 1rem;
        color: c('grey-900');
      }
      &__type {
        &--podcast {
          .c-article-list__content-wrapper {
            @include articleListIcon(
              $content: url('../../framework/images/umo/podcast-bubble.svg')
            );
          }
          background: c('theme-podcast');
        }
        &--story {
          .c-article-list__content-wrapper {
            @include articleListIcon(
              $content: url('../../framework/images/umo/story-bubble.svg')
            );
          }
          background: c('theme-story');
        }
        &--article, &--other, &--facts {
          .c-article-list__content-wrapper {
            @include articleListIcon(
              $content: url('../../framework/images/umo/article-bubble.svg')
            );
          }

          background: c('theme-article');
        }
        &--faq {
          .c-article-list__content-wrapper {
            @include articleListIcon(
              $content: url('../../framework/images/umo/faq-bubble.svg')
            );
          }
          background: c('theme-faq');
        }
        &--forum {
          .c-article-list__content-wrapper {
            @include articleListIcon(
              $content: url('../../framework/images/umo/forum-bubble.svg')
            );
          }
          background: c('theme-forum');
        }
        &--shortstory {
          .c-article-list__content-wrapper {
            @include articleListIcon(
              $content: url('../../framework/images/umo/shortstory-bubble.svg')
            );
          }
          background: c('theme-shortstory');
        }
        &--office {
          .c-article-list__content-wrapper {
            @include articleListIcon(
              $content: url('../../framework/images/umo/office-bubble.svg')
            );
          }
          background: c('theme-office');
        }
        &--support {
          .c-article-list__content-wrapper {
            @include articleListIcon(
              $content: url('../../framework/images/umo/support-bubble.svg')
            );
          }
          background: c('theme-support');
        }
        &--tip {
          .c-article-list__content-wrapper {
            @include articleListIcon(
              $content: url('../../framework/images/umo/tip-bubble.svg')
            );
          }
          background: c('theme-tip');
        }
      }
      &__content-wrapper {
        &::after {
          content: none;
        }
      }
      &__content {
        height: 100%;
        max-height: none;
        padding: 5px;
      }

      //Only one article in list
      &--full-layout {
        padding-bottom: 0;

        @include breakpoint(medium) {
          .c-article-list__content {
            height: auto;
          }
          .c-article-list__content-wrapper {
            height: 100%;
            &::before {
              top: 0;
              margin-top: 0;
            }
          }

          .medium-6:first-child {
            padding-right: 0px;
          }
          .medium-6:last-child {
            padding-left: 0px;
          }
        }
      }

      &:hover,
      &:focus {
        &::after {
          content: none;
        }
      }
    }
  }
} ;
